import React from 'react';
import './writing101.css';
import '../home.css'
import {Link} from "react-router-dom";
import ChatBot from "../Chatbot/Chatbot";
import Fireflies from "../Fireflies/Fireflies";

function Writing101() {
    return (
        <>
            <div className="hero-container">

                <div className='typed-title writing101-pic' id='writing101-pic1'>
                    <div className='typewriter'><p>WRITING 101</p></div>
                </div>
                <Fireflies/>
            </div>

            <div className="text-sections" style={{paddingTop: '7em'}}>
                <div className='writing1024' style={{paddingBottom: "3em"}}>

                    <p id="storyConcept" className="sm-caps">story concept</p>
                    <p className="writing-para">Every great story begins with a
                        unique story concept. If you were trying to sell your story idea to a publisher, it would be
                        your pitch and is usually
                        1-4 sentences. Writing a story concept is difficult because you need to concisely describe
                        your story at its most basic level. Here are some examples:
                    </p>

                    {/*Flexbox images*/}
                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/2.jpg')}
                                 alt="Katniss Everdeen" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">The Hunger Games </h4>
                            <p className="sm-text">Katniss Everdeen voluntarily takes her
                                younger sister's place in a televised competition in which two teenagers from
                                each of the twelve Districts of Panem are chosen at random to fight to the
                                death. </p>
                        </article>
                    </section>

                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/3.jpg')}
                                 alt="Mazerunner" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">The Maze Runner</h4>
                            <p className="sm-text">A group of teens appear in an area known as
                                The Glade and have no memory when they wake up. The only way out is through maze of
                                massive walls with dangerous creatures. Only Thomas holds the secret to their
                                escape.
                            </p>
                        </article>
                    </section>

                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/4.jpg')}
                                 alt="Twilight & Deadpool merged" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">Twilight meets Deadpool</h4>
                            <p className="sm-text">You can also combine your favorite stories to create something
                                new. For example, a fun vampire story where the
                                protagonist must choose between a 108-year-old vampire and a
                                hilarious mercenary.</p>
                        </article>
                    </section>


                    {/*Setting*/}
                    <p id="setting" className="sm-caps">setting </p>
                    <p className="writing-para">For your story to come to life, readers must be able to clearly
                        imagine
                        the setting. Choose <span
                            className="light-blue bold"> a place and time</span> you
                        find exciting. Ideally, it feels so real that your readers believe the place exists, at
                        least
                        while they are reading your story. The goal is to create a world you would want to spend a
                        lot of
                        time in.
                    </p>

                    <section className="feature-pair fp-transition">
                        <article className="feature-transition feature--image">
                            <img src={require('./writing101-images/5.jpg')}
                                 alt="Modor" width="440" height="400"/>
                            <img className="top" src={require('./writing101-images/5b.jpg')}
                                 alt="The Shire" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text  feature--text-top">
                            <h4 className="title-descrip">Lord of the Rings </h4>
                            <p className="sm-text">In Lord of the Rings, the Shire felt safe and welcoming. Once
                                Frodo and Sam leave the Shire however, the setting seems dangerous, scary,
                                and tests their resolve.</p>
                        </article>
                    </section>

                    <section className="feature-pair fp-transition">
                        <article className="feature-transition feature--image">
                            <img src={require('./writing101-images/6.jpg')}
                                 alt="Hogwarts stairwell" width="440" height="400"/>
                            <img className="top" src={require('./writing101-images/6b.jpg')}
                                 alt="Hogwarts Dining Hall" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text  feature--text-top">
                            <h4 className="title-descrip">Harry Potter</h4>
                            <p className="sm-text">Hogwarts School of Witchcraft and Wizardry feels magical because
                                the portraits talk, stairways move, and the dining hall is enchanted.</p>
                        </article>
                    </section>


                    {/*<p>https://www.nownovel.com/blog/talking-setting-place/#4-show-the-effects-of-time-on-setting</p>*/}
                    {/*<p> Need some inspiration?  FIND IDEAS FOR DIFFERENT SETTINGS (SETTING IDEAS)</p>*/}


                    <p id="genre" className="light-blue sm-caps genre-top">GENRE</p>
                    <p className="writing-para">Which genre interests
                        you the most? Some popular ones are mystery, thriller, dystopian, fantasy and science
                        fiction.</p>
                    <p className="indented sm-text">Other popular genres and subgenres include young adult,
                        action-adventure, romance, biographical novel, gothic, historical fiction, horror,
                        psychological, romance, whodunit mystery, amateur detective, suspense, police procedural,
                        espionage, medical thriller, courtroom drama, comedy, romantic comedy, paranormal, Christian
                        and
                        LGBTQ+.</p>

                    <p id="pov" className="pad-top3 light-blue sm-caps">POINT OF VIEW</p>
                    <p className="writing-para writing-para"> Point of view is the voice of your story. Using 'I' or
                        'we' is <span
                            className="light-blue bold">first person</span> perspective.
                        With first
                        person you only have to deal with one perspective, the character telling the story. It can
                        be
                        the
                        easiest voice to write in, but it can be limiting because you can't closely follow any other
                        characters.</p>

                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/8.jpg')}
                                 alt="The Book Thief" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">The Book Thief</h4>
                            <p className="sm-text">The Book Thief is a rare example of first person omniscient.
                                Death is
                                the narrator and tells the story about a girl named Liesel from his perspective.
                                But, he
                                also knows the thoughts and feelings of other characters and shares these with
                                readers.
                            </p>
                        </article>
                    </section>

                    <p className="writing-para">Using 'you' is <span
                        className="light-blue bold">second person</span> point of view. Second
                        person
                        allows
                        you to speak to the reader directly, but it is quirky and rarely used. <span
                            className="light-blue bold">Third person </span>uses he/she/it/they. <span
                            className="light-blue bold">Third-person omniscient</span> point of view tells the
                        thoughts and feelings of all the characters and can instantly transport the reader to any
                        setting within
                        the story. <span className="light-blue bold">Shifting the point of view</span> can keep the
                        story
                        interesting, but the challenge is to ensure every voice is distinctive so that readers don't
                        get
                        confused about whose point of view they are reading.</p>

                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/9.jpg')}
                                 alt="The Book Thief" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">Alice in Wonderland</h4>
                            <p className="sm-text">In Alice in Wonderland, the narrator speaks in third person
                                limited
                                omniscient. The narrator is completely focused on Alice and only shares her thoughts
                                and
                                feelings.
                            </p>
                        </article>
                    </section>


                    <p id="character" className="light-blue sm-caps">characters </p>
                    <p className="writing-para">You can create characters based on yourself, friends, family,
                        celebrities or pure
                        imagination. Many of your characters will be a mix of these. For example, you may create a
                        character who looks like your favorite actor, but has the character traits of a close
                        friend.</p>
                    <p className="indented sm-text">Create an outline for every character so they are clear in your
                        mind. Explain each character’s motivation, physical appearance, personality and any quirks.
                        You want their actions to be in line with their personality. If a sidekick character is
                        supposed
                        to be a funny extrovert, everything he says and does should be in line with this.</p>

                    <p className="pad-top3 writing-para">Create a <span
                        className="light-blue bold">protagonist </span> your
                        readers will find
                        interesting. If they don't care about the main character, they won't care
                        about the story either. Is your protagonist ordinary or extraordinary? How does this
                        character
                        respond to the problem or conflict? What is their
                        objective? What do they want or hope to accomplish in their journey? How does the main
                        character
                        change or evolve? Your protagonist is like a caterpillar going into his cocoon. He must
                        transform or the story is over.
                    </p>

                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/10.jpg')}
                                 alt="Spiderman" width="400" height="440"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">Spiderman</h4>
                            <p className="sm-text">Spiderman is a character-driven story that follows Peter Parker's
                                transformation.
                                He is an awkward, nerdy high school student who is
                                bitten by a mutant-spider and gains spider-like abilities. After his uncle is
                                murdered,
                                he learns to use his skills to fight evil and becomes a superhero.
                            </p>
                        </article>
                    </section>

                    <p className="writing-para">The main character is the most important because the story revolves
                        around them and their ability
                        to solve a problem or resolve a conflict. They must have clear
                        motivations and backstories (what happened in their past) so your readers understand them.
                        Your
                        protagonist must also be the most interesting and crucial to the plot.</p>

                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/11.jpg')}
                                 alt="Wizard of Oz cast" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">Dorothy</h4>
                            <p className="sm-text">While all of the characters in The Wizard of Oz are compelling,
                                Dorothy's character is strong enough to carry the story. She determines how the plot
                                develops. Readers are the most invested in what happens to her and how she is
                                changed by
                                her struggles. </p>
                        </article>
                    </section>

                    <p className="writing-para">When <span
                        className="light-blue bold">developing your characters,</span> they
                        should feel real to your readers. Describe their personality traits (funny, clever,
                        introverted)
                        and what they look like. Use details your readers can visualize: 'He was tall and lanky with
                        a
                        malicious smile and vacant gray eyes.'
                        Also include their strengths (intelligent, persistent, loyal) and weaknesses
                        (short-tempered,
                        narcissistic, socially awkward). Your characters don't have to be perfect or likeable, but
                        they
                        must be credible. </p>

                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/12.jpg')}
                                 alt="Loki" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">Loki</h4>
                            <p className="sm-text">Loki is the God of Mischief and a charming trickster and liar. He
                                is
                                also cunning, very intelligent and unpredictable. His ambition leads him to
                                betray the people he loves, but he is also good-hearted which drives him to make up
                                for
                                his bad deeds. Loki's character is believable because his actions are
                                consistent with his personality and backstory. </p>
                        </article>
                    </section>

                    <p className="writing-para">Who is the <span className="light-blue bold">antagonist </span>
                        who stands in the way of your main character achieving their goal? What is the
                        antagonist's motive? Do they have a backstory that makes their actions credible? In Lord of
                        the Rings, Sauron dreams of world domination and will do anything for it. He is
                        an evil villain who fights to stop Frodo and everyone in the fellowship of the ring. </p>

                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/13.jpg')}
                                 alt="Voldemort" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">Voldemort</h4>
                            <p className="sm-text">Lord Voldemort is a worthy antagonist in Harry Potter and has an
                                interesting backstory. Voldemort's muggle father left his mother when she was
                                pregnant
                                when he discovered she was a witch. His mother died giving birth, leaving Voldemort
                                to
                                be raised in a Muggle orphanage. He never experienced love in his life.</p>
                        </article>
                    </section>

                    <p className="writing-para"><span className="light-blue bold">Supporting characters </span> help
                        the
                        main character
                        achieve their goals. For example Hermione and Ron in the Harry Potter Series help Harry.
                        And in doing so, readers love them, root for them, and feel upset when they're harmed. Each
                        character also has distinctive traits that make them unique. Supporting characters can also
                        serve as enemies who create obstacles, like Draco Malfoy.
                    </p>
                    <div className="three-imgs">
                        <img className="one-line" src={require('./writing101-images/14.jpg')}
                             style={{paddingRight: "20px"}} alt="Hermione"/>
                        <img className="one-line" src={require('./writing101-images/15.jpg')}
                             style={{paddingRight: "20px"}} alt="Ron"/>
                        <img className="one-line" src={require('./writing101-images/16.jpg')}
                             alt="Malfoy"/>
                    </div>
                    <p className="sm-text indented">Hermione is very intelligent, well-read, ambitious and
                        adventurous.
                        Ron is a funny, loyal, sometimes lazy, red-head. Draco is a cowardly bully who tricks and
                        hurts
                        people to get what he wants. Throughout the Harry Potter series, their actions consistently
                        support the character traits they're known for.</p>

                    {/*Plot*/}
                    <p id="plot" className="light-blue sm-caps pad-top3">Plot & conflict </p>
                    <p className="writing-para"> What happens in your story that turns the world upside down for the
                        main character? Your plot
                        should include a crisis, struggle,
                        discovery, and a transformation of the main character. Everything should revolve around one
                        main
                        conflict. </p>

                    <div className="conflict-pad">
                        <p className="writing-para"><span style={{fontSize: '100%'}} className="light-blue sm-caps">Types of conflict</span>
                        </p>
                        <ol className="lists">
                            <li>Character vs. Character(s)</li>
                            <li>Character vs. Society</li>
                            <li> Character vs. Nature</li>
                            <li>Character vs. Technology</li>
                            <li>Character vs. Supernatural</li>
                            <li>Character vs. Fate</li>
                            <li>Character vs. Self</li>
                        </ol>
                    </div>
                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/17.jpg')}
                                 alt="The Giver" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">Character vs Society</h4>
                            <p className="sm-text">In The Giver, Jonas lives in a seemingly perfect world of
                                conformity
                                and contentment. But he soon discovers the dangerous truth about his community and
                                what
                                they will do to enforce "Sameness". His challenge is to escape; something no one has
                                ever done. </p>
                        </article>
                    </section>

                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/18.jpg')}
                                 alt="Harry Potter and Voldemort" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">Character vs Character</h4>
                            <p className="sm-text">All seven Harry Potter books focus on Harry's fight with the
                                villainous Voldemort.
                            </p>
                        </article>
                    </section>

                    <p className="writing-para">Most stories are broken into three acts. <span
                        className="light-blue bold">Act One</span> is the setup. Stories usually begin with the
                        main character living a fairly boring life (status quo). Then something sparks a conflict
                        that
                        changes everything (inciting incident).
                    </p>

                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/19.jpg')}
                                 alt="Hagrid and Harry Potter" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">Act One</h4>
                            <p className="sm-text"> When Harry Potter is a baby his parents are murdered and he is
                                raised by the cruel
                                Dursleys. When he turns 11, the Dursleys try to keep him from attending the
                                Hogwarts School of Witchcraft and Wizardry. Hagrid, however, tracks Harry down and
                                takes
                                him to the school to begin his training and adventure.
                            </p>
                        </article>
                    </section>

                    <p id="hook" className="light-blue sm-caps"> The hook </p>
                    <p className="writing-para">Your story should begin with a hook that grabs your reader's
                        attention.
                        For example, the Harry
                        Potter series begins: "Mr. and Mrs.
                        Dursley of number four, Privet Drive, were proud to say that they were perfectly normal,
                        thank
                        you very much." Below are other examples of great opening lines:<br/><br/>
                    </p>
                    <div className="three-to-one">
                        <div className='home-bottom borderbox w-300'>
                            <p className="three-descrip">The Invisible Man </p>
                            <p className="sm-text">"I am an invisible man." </p>
                        </div>
                        <div className='home-bottom borderbox w-300'>
                            <p className="three-descrip" style={{textAlign: 'left'}}>The Outsider</p>
                            <p className="sm-text">"Mother died today. Or maybe, yesterday; I
                                can't be sure."
                            </p>
                        </div>
                        <div className='home-bottom borderbox w-300'>
                            <p className="three-descrip" style={{textAlign: 'left'}}>I Capture the Castle </p>
                            <p className="sm-text">"I write this sitting in the kitchen
                                sink." </p>
                        </div>
                    </div>

                    <p className="pad-top3 writing-para">You can hook your readers at the beginning of your story by
                        using one of the
                        following:</p>

                    <div className="examples-container">
                        <div className='example-items w-300 borderbox'>
                            <p className="hook-descrip">Dialogue</p>
                            <p className="sm-text">"You can't leave me here!" I shouted. "I
                                don't have any money."
                            </p>
                        </div>
                        <div className='example-items w-300 borderbox'>
                            <p className="hook-descrip">A Question</p>
                            <p className="sm-text">"If I could tell you the exact moment you
                                would die, would you want to know?"
                            </p>
                        </div>
                        <div className='example-items w-300 borderbox'>
                            <p className="hook-descrip">Something Shocking</p>
                            <p className="sm-text">"I lost my leg on my way to Nevada. But
                                that wasn't the worst thing that would happen."
                            </p>
                        </div>
                        <div className='example-items w-450 borderbox'>
                            <p className="hook-descrip">Introduce Your Antagonist First</p>
                            <p className="sm-text">In the 2012 movie The Avengers, Loki was
                                introduced first when 'The Other' tells Thanos that Loki is ready to lead: "The
                                world
                                will be his, the universe will be yours."
                                Then, in the opening scene, Loki steals the Tesseract.
                            </p>
                        </div>
                        <div className='example-items w-450 borderbox'>
                            <p className="hook-descrip">Begin in the Middle of the Story</p>
                            <p className="sm-text">"No one knew if the hostages were alive or
                                dead.” Readers will want to know if the hostages make it out alive and will keep
                                reading. After
                                they
                                are hooked, you can tell them how things started. </p>
                        </div>
                    </div>

                    <p className="pad-top3 writing-para">Act One should include the introduction of your main
                        characters,
                        their world, and an inciting incident that turns the world upside down for your
                        protagonist.</p>

                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/20.jpg')}
                                 alt="two teens running from zombies" width="476" height="593"/>
                        </article>
                        <article className="feature feature--text" style={{padding: '0 2em'}}>
                            <h4 id="intro" className="title-descrip">Introduction</h4>
                            <p className="sm-text" style={{lineHeight: '30px'}}>PICK ME.<br/>
                                It’s all I can do not to scream. I dig my nails into the marula oak of my staff and
                                squeeze to keep from fidgeting. Beads of sweat drip down my back, but I can’t tell
                                if it’s from dawn’s early heat or from my heart slamming against my chest. Moon
                                after
                                moon I’ve been passed over. Today can’t be the same. I tuck a lock of snow-white
                                hair
                                behind my ear and do my best to sit still. As always,
                                Mama Agba makes the selection grueling, staring at each girl just long enough to
                                make us
                                squirm.
                            </p>
                        </article>
                    </section>

                    <p className="writing-para">In <span className="light-blue bold">Act Two,</span> one or more
                        events happen that complicate the situation. The main character confronts challenges that
                        force
                        them to grow and change. For example, in The Sorceror's Stone, Harry and his new friends
                        must
                        fight a troll, play in a dangerous quidditch match, and survive a wizard's game of giant
                        chess.
                        These 'sparks' keep the ball rolling and are important to Harry's development as a hero.
                    </p>
                    <div className="three-imgs">
                        <img className="one-line" src={require('./writing101-images/21.jpg')}
                             style={{paddingRight: "20px"}} alt="troll"/>
                        <img className="one-line" src={require('./writing101-images/22.jpg')}
                             style={{paddingRight: "20px"}} alt="quidditch"/>
                        <img className="one-line" src={require('./writing101-images/23.jpg')}
                             alt="chess"/>
                    </div>

                    <p className="pad-top3 writing-para">In <span className="light-blue bold">Act Three, </span>
                        the final act, the stakes are high and the conflict is resolved. Many stories create 'last
                        minute tension' by making readers believe the main character may fail in their journey. </p>
                    <p className="writing-para">Harry and his friends struggle to overcome numerous obstacles to
                        uncover
                        the secret that is being
                        guarded by the school. Then, on his own, Harry must face Voldemort, the evil wizard who
                        killed
                        his parents. The resolution of the plot happens when he obtains the Philosopher's Stone,
                        kills
                        Professor
                        Quirrel and Voldemort
                        becomes powerless. The conflict is resolved.
                    </p>
                    <div className="three-imgs">
                        <img className="one-line-wide" src={require('./writing101-images/24.jpg')}
                             style={{paddingRight: "20px"}} alt="troll"/>
                        <img className="one-line-wide" src={require('./writing101-images/25.jpg')}
                             style={{paddingRight: "20px"}} alt="quidditch"/>
                        <img className="one-line-wide" src={require('./writing101-images/26.jpg')}
                             alt="chess"/>
                    </div>
                    <p className='pad-top3 writing-para'>As Act Three comes to an end, all loose ends are tied up.
                        This
                        is called the
                        <span className="light-blue bold">Falling Action.</span></p>
                    <section className="feature-pair">
                        <article className="feature feature--image">
                            <img src={require('./writing101-images/27.jpg')}
                                 alt="Harry Potter and friends" width="440" height="400"/>
                        </article>
                        <article className="feature feature--text">
                            <h4 className="title-descrip">Falling Action </h4>
                            <p className="sm-text">Harry and his friends are rewarded at the Hogwarts ceremony for
                                their
                                courageous acts and their friendships are solidified. Harry returns home to the
                                Dursley's, but he is a different person. By the end of the story he learned to voice
                                his ideas, trust his abilities and became confident in himself.
                            </p>
                        </article>
                    </section>

                    <p className="light-blue sm-caps">resolution </p>
                    <p className="writing-para">Your readers should walk away with a feeling that the story is
                        complete
                        and your main character
                        has been transformed. </p>
                    <p className="writing-para">Every reader expects a <span
                        className="light-blue bold">great ending</span> and it's much
                        easier to
                        write one if you have it planned out in advance. This is because you can
                        ensure all the action leads to the finale you want. If you just wing it and start writing to
                        see
                        where the story takes you, it can end in a way that doesn't make sense or leaves readers
                        disappointed. </p>
                    <p className="writing-para">Before you write your first paragraph, you should be able to answer
                        these
                        questions:</p>
                    <ul className="list-text" style={{listStyleType: 'decimal'}}>
                        <li>Who are you writing for (who is your audience)?</li>
                        <li>What is the main conflict your protagonist must resolve?</li>
                        <li>How will the conflict be resolved?</li>
                        <li>Do the characters and setting seem real? If not, use details that bring them to life.
                        </li>
                        <li>What obstacles will your characters face and overcome?</li>
                        <li>What will your protagonist learn?</li>
                        <li>How will the characters change?</li>
                        <li>How will the main character's life be different when the story ends?</li>
                        <li>Will their world be changed in some way?</li>
                        <li>Are there any parts of the story that don't fit or are not necessary to include?</li>
                        <li>Does the ending fit with the rest of the story and seem credible?</li>
                    </ul>
                    <br/>
                    <p className="light-blue sm-caps">editing </p>
                    <p className="writing-para"> Many writers spend most of their time editing and
                        revising their stories (sometimes hundreds of times). It's best to edit your story after you
                        have written the entire thing. Then, once it's written:</p>
                    <ul className="list-text" style={{listStyleType: 'circle'}}>
                        <li>Fix all spelling and grammar mistakes. You can go to the <Link to="/story-ideas"
                                                                                           reloadDocument={true}>AI
                            grammar page</Link> to get help with this.
                        </li>
                        <li>Read your story out loud. Mark anything that is unclear. If something doesn't make sense
                            to
                            you, it won't make sense to your reader.
                        </li>
                        <li>Explain anything that isn't obvious to your reader. If your main character is afraid to
                            go
                            on a boat, explain that he nearly drowned in a boating accident when he was younger.
                        </li>
                        <li>Correct any sentences that sound wordy or redundant. For example:</li>
                        <ul className="list-text">
                            <li>It was small in size. = It was small.</li>
                            <li>She smiled happily. = She smiled.</li>
                            <li>Denny drove crazily, chaotically, and wildly down the road. = Denny drove crazily
                                down
                                the road.
                            </li>
                        </ul>
                        <li>Use an active, versus passive, voice. <i>The boy hit the troll</i> is better than <i>The
                            troll was hit by the boy.</i></li>
                        <li>Leave out the boring parts. If you have any paragraphs that aren't necessary to the
                            story,
                            remove them. The horror writer Stephen King removes 10% of his novel by the 2nd draft.
                            At
                            best, readers will skim over unnecessary paragraphs. At worst, they will stop reading
                            altogether.
                        </li>
                        <li>Always show emotion with action, not an exclamation point.
                            In other words, show how someone is feeling. Don't write: "I'm going to kill you!" she
                            exclaimed emphatically.
                            Instead, write: "I'm going to kill you," she said throwing a rock at his face. It struck
                            his
                            forehead and he stumbled backwards.
                        </li>

                    </ul>
                    <br/>

                    <p className="writing-para">It's hard work to put your thoughts into words and tell a good
                        story.
                        But with every story you
                        write and every revision you make, writing becomes a little
                        easier and you become a better storyteller.</p>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default Writing101;