import React from 'react'
import './fireflies.css'

const Fireflies = () => {
    return (
        <div className="firefly-full-width">
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
            <div className="firefly"/>
        </div>
    )
}
 export default Fireflies