import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory44 = () => {

// LocalStorage
    const [story44, setstory44] = useLocalStorageState("story44", "")
    // Save if changes are made
    const onChangeStory44Listener = (event) => {
        setstory44(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>An Egyptian Mystery</h1>
                    <form>
                        <p className="ai-story-intro">Neema has a knack for solving mysteries, so when a priceless artifact goes missing from
                            the ancient Egyptian exhibit at the Metropolitan Museum of Art, her detective instincts
                            are all abuzz. Joined by her best friend, the brainy and bibliophile Kesi, the two girls
                            sleuth their way through the crowded streets of Cairo, trying to track down the thief.
                            But they’re not the only ones on the case. The museum’s security team, led by the
                            dashing and mysterious Mr. Tarik, is also hot on the trail of the thief. As the clues
                            pile up, Neema and Kesi race against the clock to find the thief and save the day.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory44Listener} value={story44}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory44;

