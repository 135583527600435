import React from "react";

export function useLocalStorageState(key, defaultValue = "") {
    const [state, setState] = React.useState(
        () => window.localStorage.getItem(key) || defaultValue
    )
    React.useEffect(() => {
        window.localStorage.setItem(key, state)
    }, [state, key])
    return [state, setState]
}
