import './App.css';

import MobileNav from './components/Menu'
import Home from './components/Home'
import AIStories from "./components/AIStories/AIStories";
import AIStoriesIndex from "./components/AIStories/AIStoryPages/AIStoriesIndex/AIStoriesIndex";
import Outline from "./components/MyStories/StoryOutline/StoryOutline"
import Story from "./components/MyStories/Storybox/Storybox";
import Writing101 from "./components/Writing101/Writing101";
import Story1 from "./components/AIStories/AIStoryPages/Story1";
import Story2 from "./components/AIStories/AIStoryPages/Story2";
import Story3 from "./components/AIStories/AIStoryPages/Story3";
import Story4 from "./components/AIStories/AIStoryPages/Story4";
import Story5 from "./components/AIStories/AIStoryPages/Story5";
import Story6 from "./components/AIStories/AIStoryPages/Story6";
import Story7 from "./components/AIStories/AIStoryPages/Story7";
import Story8 from "./components/AIStories/AIStoryPages/Story8";
import Story9 from "./components/AIStories/AIStoryPages/Story9";
import Story10 from "./components/AIStories/AIStoryPages/Story10";
import Story11 from "./components/AIStories/AIStoryPages/Story11";
import Story12 from "./components/AIStories/AIStoryPages/Story12";
import Story13 from "./components/AIStories/AIStoryPages/Story13";
import Story14 from "./components/AIStories/AIStoryPages/Story14";
import Story15 from "./components/AIStories/AIStoryPages/Story15";
import Story16 from "./components/AIStories/AIStoryPages/Story16";
import Story17 from "./components/AIStories/AIStoryPages/Story17";
import Story18 from "./components/AIStories/AIStoryPages/Story18";
import Story19 from "./components/AIStories/AIStoryPages/Story19";
import Story20 from "./components/AIStories/AIStoryPages/Story20";
import Story21 from "./components/AIStories/AIStoryPages/Story21";
import Story22 from "./components/AIStories/AIStoryPages/Story22";
import Story23 from "./components/AIStories/AIStoryPages/Story23";
import Story24 from "./components/AIStories/AIStoryPages/Story24";
import Story25 from "./components/AIStories/AIStoryPages/Story25";
import Story26 from "./components/AIStories/AIStoryPages/Story26";
import Story27 from "./components/AIStories/AIStoryPages/Story27";
import Story28 from "./components/AIStories/AIStoryPages/Story28";
import Story29 from "./components/AIStories/AIStoryPages/Story29";
import Story30 from "./components/AIStories/AIStoryPages/Story30";
import Story31 from "./components/AIStories/AIStoryPages/Story31";
import Story32 from "./components/AIStories/AIStoryPages/Story32";
import Story33 from "./components/AIStories/AIStoryPages/Story33";
import Story34 from "./components/AIStories/AIStoryPages/Story34";
import Story35 from "./components/AIStories/AIStoryPages/Story35";
import Story36 from "./components/AIStories/AIStoryPages/Story36";
import Story37 from "./components/AIStories/AIStoryPages/Story37";
import Story38 from "./components/AIStories/AIStoryPages/Story38";
import Story39 from "./components/AIStories/AIStoryPages/Story39";
import Story40 from "./components/AIStories/AIStoryPages/Story40";
import Story41 from "./components/AIStories/AIStoryPages/Story41";
import Story42 from "./components/AIStories/AIStoryPages/Story42";
import Story43 from "./components/AIStories/AIStoryPages/Story43";
import Story44 from "./components/AIStories/AIStoryPages/Story44";
import Story45 from "./components/AIStories/AIStoryPages/Story45";
import Story46 from "./components/AIStories/AIStoryPages/Story46";
import Story47 from "./components/AIStories/AIStoryPages/Story47";
import Story48 from "./components/AIStories/AIStoryPages/Story48";
import Story49 from "./components/AIStories/AIStoryPages/Story49";
import Story50 from "./components/AIStories/AIStoryPages/Story50";
import Story51 from "./components/AIStories/AIStoryPages/Story51";
// GPT-3 Elements
// import Title from "./components/GPT/Title"
// import NewStoryIdea from "./components/GPT/NewStoryIdea";
// import Introduction from "./components/GPT/Introduction";
// import Setting from "./components/GPT/Setting";
// import NextParagraph from "./components/GPT/NextParagraph";
// import Character from "./components/GPT/Character";
// import Names from "./components/GPT/Names";
// import Plot from "./components/GPT/Plot";
// import Grammar from "./components/GPT/Grammar"
// import Dialogue from "./components/GPT/Dialogue"
// import Other from "./components/GPT/Other";
// import Prompts from "./components/GPT/Prompts";
// import StoryConcept from "./components/GPT/StoryConcept";
// import GPTHelpIndex from "./components/GPT/GPTHelpIndex";

// import About from "./components/About/About"

import {BrowserRouter, Routes, Route} from "react-router-dom";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <MobileNav/>
                <Routes>
                    <Route path="/" exact element={<Home/>}/>
                    <Route path="/story-ideas" exact element={<AIStories/>}/>
                    <Route path="/storyideas-index" exact element={<AIStoriesIndex/>}/>
                    <Route path="/writing101" exact element={<Writing101/>}/>
                    <Route path="/story-outline" exact element={<Outline/>}/>
                    <Route path="/my-stories" exact element={<Story/>}/>
                    {/*<Route  path="/about" exact element={<About/>}/>*/}

                    {/*AI Stories*/}
                    <Route path="/story1" exact element={<Story1/>}/>
                    <Route path="/story2" exact element={<Story2/>}/>
                    <Route path="/story3" exact element={<Story3/>}/>
                    <Route path="/story4" exact element={<Story4/>}/>
                    <Route path="/story5" exact element={<Story5/>}/>
                    <Route path="/story6" exact element={<Story6/>}/>
                    <Route path="/story7" exact element={<Story7/>}/>
                    <Route path="/story8" exact element={<Story8/>}/>
                    <Route path="/story9" exact element={<Story9/>}/>
                    <Route path="/story10" exact element={<Story10/>}/>
                    <Route path="/story11" exact element={<Story11/>}/>
                    <Route path="/story12" exact element={<Story12/>}/>
                    <Route path="/story13" exact element={<Story13/>}/>
                    <Route path="/story14" exact element={<Story14/>}/>
                    <Route path="/story15" exact element={<Story15/>}/>
                    <Route path="/story16" exact element={<Story16/>}/>
                    <Route path="/story17" exact element={<Story17/>}/>
                    <Route path="/story18" exact element={<Story18/>}/>
                    <Route path="/story19" exact element={<Story19/>}/>
                    <Route path="/story20" exact element={<Story20/>}/>
                    <Route path="/story21" exact element={<Story21/>}/>
                    <Route path="/story22" exact element={<Story22/>}/>
                    <Route path="/story23" exact element={<Story23/>}/>
                    <Route path="/story24" exact element={<Story24/>}/>
                    <Route path="/story25" exact element={<Story25/>}/>
                    <Route path="/story26" exact element={<Story26/>}/>
                    <Route path="/story27" exact element={<Story27/>}/>
                    <Route path="/story28" exact element={<Story28/>}/>
                    <Route path="/story29" exact element={<Story29/>}/>
                    <Route path="/story30" exact element={<Story30/>}/>
                    <Route path="/story31" exact element={<Story31/>}/>
                    <Route path="/story32" exact element={<Story32/>}/>
                    <Route path="/story33" exact element={<Story33/>}/>
                    <Route path="/story34" exact element={<Story34/>}/>
                    <Route path="/story35" exact element={<Story35/>}/>
                    <Route path="/story36" exact element={<Story36/>}/>
                    <Route path="/story37" exact element={<Story37/>}/>
                    <Route path="/story38" exact element={<Story38/>}/>
                    <Route path="/story39" exact element={<Story39/>}/>
                    <Route path="/story40" exact element={<Story40/>}/>
                    <Route path="/story41" exact element={<Story41/>}/>
                    <Route path="/story42" exact element={<Story42/>}/>
                    <Route path="/story43" exact element={<Story43/>}/>
                    <Route path="/story44" exact element={<Story44/>}/>
                    <Route path="/story45" exact element={<Story45/>}/>
                    <Route path="/story46" exact element={<Story46/>}/>
                    <Route path="/story47" exact element={<Story47/>}/>
                    <Route path="/story48" exact element={<Story48/>}/>
                    <Route path="/story49" exact element={<Story49/>}/>
                    <Route path="/story50" exact element={<Story50/>}/>
                    <Route  path="/story51" exact element={<Story51/>}/>
                    {/*End AI Stories*/}

                    {/*GPT-3 Help Pages*/}
                    {/*<Route  path="/title" exact element={<Title/>}/>*/}
                    {/*<Route  path="/story-idea" exact element={<NewStoryIdea/>}/>*/}
                    {/*<Route  path="/introduction" exact element={<Introduction/>}/>*/}
                    {/*<Route  path="/setting" exact element={<Setting/>}/>*/}
                    {/*<Route  path="/next-paragraph" exact element={<NextParagraph/>}/>*/}
                    {/*<Route  path="/character" exact element={<Character/>}/>*/}
                    {/*<Route  path="/names" exact element={<Names/>}/>*/}
                    {/*<Route  path="/plot" exact element={<Plot/>}/>*/}
                    {/*<Route  path="/grammar" exact element={<Grammar/>}/>*/}
                    {/*<Route  path="/dialogue" exact element={<Dialogue/>}/>*/}
                    {/*<Route  path="/other" exact element={<Other/>}/>*/}
                    {/*<Route  path="/prompts" exact element={<Prompts/>}/>*/}
                    {/*<Route  path="/story-concept" exact element={<StoryConcept/>}/>*/}

                    {/*<Route  path="/gpt-help-index" exact element={<GPTHelpIndex/>}/>*/}

                    {/*End GPT-3 help pages*/}
                    {/*<Route path="/printables" exact element={<Printables/>}/>*/}

                </Routes>
            </div>
        </BrowserRouter>
    );
}


export default App;