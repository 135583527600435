import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";

const AIStory10 = () => {

// LocalStorage
    const [story10, setstory10] = useLocalStorageState("story10", "")
    // Save if changes are made
    const onChangeStory10Listener = (event) => {
        setstory10(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Off the Grid</h1>
                    <form>
                        <p className="ai-story-intro">When the small plane carrying humanitarian aid worker, Aaron Spencer, crashes in the
                            middle of the African jungle, he is sure he is doomed. With no signal for rescue,
                            Spencer must face the harsh elements of the jungle and the dangers of the wildlife
                            alone. As he forages for food and fights off predators, Spencer begins questioning
                            everything he thought he knew about himself and the world around him. Slowly, he comes
                            to understand that the jungle may be a harsh place, but it is also a place of beauty and
                            mystery.</p>
                        <textarea className="ai-box" onChange={onChangeStory10Listener} value={story10}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p or right click and select Print..."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory10;

