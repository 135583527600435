import React from 'react';
import '../storybox.css';

const StoriesList = ({stories, editStory, deleteStory}) => {

    return stories.length ? (
    stories.map(story => <div className="story" key={story.id}>{story.story}
      {/*<span className="story-edit" onClick={() => editStory(story.id)}><FaPencilAlt /></span>*/}
      {/*<span className="story-delete" onClick={() => deleteStory(story.id)}><FaTrashAlt /></span>*/}
      <div className="story-edit"><button className="edit-btn" onClick={() => editStory(story.id)}>EDIT</button></div>
        {/*<div className="story-delete"><button onClick={() => deleteStory(story.id)}>DELETE</button></div>*/}
        <div className="story-delete"><button className="border-orange" onClick={() => {if(window.confirm('Are you sure you want to delete this story?')){deleteStory(story.id)};}}>DELETE</button></div>
    </div>)
  ) : null
}

export default StoriesList;