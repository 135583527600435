import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory2 = () => {

// LocalStorage
    const [story2, setstory2] = useLocalStorageState("story2", "")
    // Save if changes are made
    const onChangeStory2Listener = (event) => {
        setstory2(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>A Vampire's Story</h1>
                    <form>
                        <p className="ai-story-intro">Many people think of vampires as creatures of the night, who stalk their prey in dark alleys and lurk in shadows. But what if vampires were something else entirely? What if they were like you and me? Meet Kaelyn, a young vampire who has just moved to a new town. She's never been the best at making friends, but she's determined to fit in this time. Kaelyn is different from other vampires in one very important way: she doesn't drink blood.  Her plan was to make a lot of friends and blend in.  But her plan goes sideways when she meets Jake and they fall head over heels for each other. </p>
                        <textarea className="ai-box" onChange={onChangeStory2Listener} value={story2}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

    <ChatBot
        position={"robot-icon-position"}
        scale={"robot robot-scale-icon"}
        gradient={"robot-radial-gradient-none"}
        robotText={"robot-text-none"}
    />
</>
    )
}

export default AIStory2;

