import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory18 = () => {

// LocalStorage
    const [story18, setstory18] = useLocalStorageState("story18", "")
    // Save if changes are made
    const onChangeStory18Listener = (event) => {
        setstory18(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Proxima</h1>
                    <form>
                        <p className="ai-story-intro">Leo had always dreamed of leaving Earth, but he never imagined he
                            would actually get the chance. That is, until he was chosen to join the first human colony
                            on the planet Proxima. Scientists called it a super-Earth with everything needed to sustain
                            human life. The assignment was a dream come true, but also a daunting challenge. Proxima has
                            a harsh environment and unpredictable weather. Every day, the landscape changes as massive
                            glaciers on the planet's equator move. They have also encountered creatures who adapted to
                            survive the constantly changing conditions. As the colonists begin to build their new home,
                            Leo quickly realizes that life on Proxima is going to be anything but easy. He soon finds
                            himself fighting for survival against the elements and the native creatures. Luckily, he is
                            not on his own. He befriends Tom, someone who proves to be one of his closest friends. He
                            also becomes close friends Natasha, a one-woman assault force who will save his life more
                            than once.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory18Listener} value={story18}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory18;

