import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory20 = () => {

// LocalStorage
    const [story20, setstory20] = useLocalStorageState("story20", "")
    // Save if changes are made
    const onChangeStory20Listener = (event) => {
        setstory20(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Suddenly a Spy</h1>
                    <form>
                        <p className="ai-story-intro">My name is Layla, and I'm a spy. I'm not the kind of person
                            you would ever expect to be a spy and maybe that's why the UK's Secret Intelligence
                            Service recruited me. I was born
                            and raised in a small town in the middle of nowhere. I had a normal childhood, went to a
                            normal school, and had the normal dreams of going to college and getting a good job. But
                            then, when I was eighteen, I was recruited by the MI6 and accepted the job. I started
                            off with a fairly safe desk job intercepting secret messages sent by spies and foreign
                            leaders. Then one day, my boss, Nathaniel Addison, tells me that I'm being transferred
                            to a field position in Paris, France. It was unexpected and I didn't know what was
                            coming. Little did I know I would be sent on the most dangerous mission of my
                            career.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory20Listener} value={story20}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory20;

