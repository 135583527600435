import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory39 = () => {

// LocalStorage
    const [story39, setstory39] = useLocalStorageState("story39", "")
    // Save if changes are made
    const onChangeStory39Listener = (event) => {
        setstory39(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Equestrian Magic</h1>
                    <form>
                        <p className="ai-story-intro">When Emma moves to the country to live with her grandparents, she expects to spend her
                            summer riding her horse, Belle, and helping out on the family farm. But she soon
                            discovers there's a lot more to running a farm than she realized. Her grandfather is
                            struggling to keep the farm afloat financially. What's more, her new friend,
                            Lucas, tells her about the magical creatures that live on the farm and the important
                            role they play in the farm's success. Emma is determined to help her grandfather, but
                            she quickly learns that it's not going to be easy. There is a corrupt investor
                            from the city who will do whatever he can to take the farm so he can sell the creatures
                            for a huge profit. But with Lucas's help, she just might be able to save the farm and their way of
                            life.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory39Listener} value={story39}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory39;

