import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory45 = () => {

// LocalStorage
    const [story45, setstory45] = useLocalStorageState("story45", "")
    // Save if changes are made
    const onChangeStory45Listener = (event) => {
        setstory45(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Finding a New Planet</h1>
                    <form>
                        <p className="ai-story-intro">The year is 2060. Humanity has been forced to abandon earth after a devastating
                            cataclysm destroys the planet. But a new home is discovered in another solar system close to
                            the earth and a team of brave astronauts set out to explore it. They're not the only ones interested in this new
                            world though. Alien lifeforms are also on the hunt for it, and they'll stop at nothing
                            to get there first. It's up to the astronauts to discover the secrets of this new world
                            before the aliens do. Their team is led by the heroic Commander Veronica Reeves, and the
                            slightly nerdy but very brave Lieutenant Mark Benson.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory45Listener} value={story45}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory45;

