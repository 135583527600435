import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory21 = () => {

// LocalStorage
    const [story21, setstory21] = useLocalStorageState("story21", "")
    // Save if changes are made
    const onChangeStory21Listener = (event) => {
        setstory21(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Kingdom of Eshtaol</h1>
                    <form>
                        <p className="ai-story-intro">The kingdom of Eshtaol was in peril. The evil wizard, Malric, had
                            cast a spell that had turned the king and queen into stone. The only hope for the kingdom
                            was the young prince, who had been sent away to live in safety. But when Malric learned of
                            the prince's whereabouts, he sent his minions to capture him. The prince narrowly escaped
                            and found himself in a strange and dangerous land. With the help of some new friends, he set
                            out to find a way to break the spell and save his parents.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory21Listener} value={story21}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory21;

