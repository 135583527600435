import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory48 = () => {

// LocalStorage
    const [story48, setstory48] = useLocalStorageState("story48", "")
    // Save if changes are made
    const onChangeStory48Listener = (event) => {
        setstory48(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Taking Over</h1>
                    <form>
                        <p className="ai-story-intro">Most humans believe that if there is intelligent life out there in the universe, it
                            would be similar to us. But what if the aliens are nothing like us at all? What if they
                            are slimy, scaly creatures with razor sharp claws and teeth? And what if they want to
                            conquer Earth and enslave humanity? This is the world John Taylor must
                            face when he is taken captive by a group of aliens called the Skrell. The Skrell are a
                            cruel, heartless race and John is determined to find a way to escape from them. Along
                            the way, he meets other captives who help him in his quest, including a beautiful girl
                            named Jillian.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory48Listener} value={story48}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory48;

