import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory36 = () => {

// LocalStorage
    const [story36, setstory36] = useLocalStorageState("story36", "")
    // Save if changes are made
    const onChangeStory36Listener = (event) => {
        setstory36(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>2165</h1>
                    <form>
                        <p className="ai-story-intro">In the year 2165, the world is a very different place. The
                            planet is a barren wasteland, the result of a cataclysmic event that took place in 2028.
                            The remaining humans have retreated into underground societies. They rarely venture out;
                            traveling by land is treacherous. But humanity has not given up. They have continued to
                            build and innovate, and now they have created something that could change everything:
                            robots. These robots are unlike anything the world has ever seen. They are smarter,
                            faster, and stronger than any human. One of the underground societies, known as the Ark,
                            has decided to send a team of robots on a trip across the dangerous landscape to help
                            humans colonize the surface. They are led by the meek, mild-mannered Kitaru and his
                            extremely ambitious, yet reckless partner, Shigeru.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory36Listener} value={story36}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory36;

