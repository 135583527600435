import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory42 = () => {

// LocalStorage
    const [story42, setstory42] = useLocalStorageState("story42", "")
    // Save if changes are made
    const onChangeStory42Listener = (event) => {
        setstory42(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Floating Cities</h1>
                    <form>
                        <p className="ai-story-intro">In a future world where the seas have risen and swallowed up much of the land, the last
                            humans live on floating cities built from the wreckage of the old world. For Jessie,
                            life on the floating city of New Chicago is a lonely one. With her mother dead and her
                            father always working, Jessie has no one to talk to and nothing to do but wander the
                            empty hallways of the city. But when a group of kids show up out of the blue,
                            Jessie finds herself with some unexpected, new friends. They're a ragtag
                            group of kids who live on a broken-down old ship called the Ransom, and they're on a
                            mission to find a way to fix their city.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory42Listener} value={story42}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>

    )
}

export default AIStory42;

