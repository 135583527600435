import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory26 = () => {

// LocalStorage
    const [story26, setstory26] = useLocalStorageState("story26", "")
    // Save if changes are made
    const onChangeStory26Listener = (event) => {
        setstory26(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="max1024">
                    <div className="ai-container">
                        <h1>Dark Hero</h1>
                        <form>
                            <p className="ai-story-intro">The city sleeps. The only sound that can be heard is the faint
                                humming of the
                                streetlights. The occasional car drives down the road; the sound of the tires hitting
                                the asphalt is the only disruption to the silence. But the peace is short-lived. A
                                shadow
                                moves in the darkness, a figure leaping from rooftop to rooftop. The figure is faster
                                than any mortal. Soon the city is alive with the sound of sirens. This is not a creature
                                of nightmare, but a superhero, defending the innocent and saving the city from those who
                                would do harm.
                            </p>
                            <textarea className="ai-box" onChange={onChangeStory26Listener} value={story26}
                                      placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                        </form>
                    </div>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory26;

