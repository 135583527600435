import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory6 = () => {

// LocalStorage
    const [story6, setstory6] = useLocalStorageState("story6", "")
    // Save if changes are made
    const onChangeStory6Listener = (event) => {
        setstory6(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>The Secret War</h1>
                    <form>
                        <p className="ai-story-intro">In the summer of 1942, 15-year-old Anne-Marie Picard is eager to
                            spend her vacation in the south of France, where she and her family have a vacation home.
                            But when the Nazis invade and occupy France, Anne-Marie's vacation turns into a nightmare.
                            She is forced to flee her home and take refuge in the mountain village of Le Chambon, where
                            the people have taken a pledge to protect and hide Jews from the Nazis. With the help of her
                            new friends, Anne-Marie must find the courage to face the dangers of the war and survive
                            against all odds. </p>
                        <textarea className="ai-box" onChange={onChangeStory6Listener} value={story6}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory6;

