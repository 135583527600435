import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory5 = () => {

// LocalStorage
    const [story5, setstory5] = useLocalStorageState("story5", "")
    // Save if changes are made
    const onChangeStory5Listener = (event) => {
        setstory5(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Find the Killer</h1>
                    <form>
                        <p className="ai-story-intro">I’m not sure who I should be more worried about, the killer or the
                            police. In the past week, I’ve had two run-ins with the law. The first was when I was caught
                            snooping around the scene of the latest murder. The second was when I was found standing
                            over the victim’s body with the murder weapon in my hand. I swear I’m not the killer, but
                            the police aren’t so sure. I guess I’ll just have to find the real killer before they find
                            me.</p>
                        <textarea className="ai-box" onChange={onChangeStory5Listener} value={story5}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory5;

