import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory43 = () => {

// LocalStorage
    const [story43, setstory43] = useLocalStorageState("story43", "")
    // Save if changes are made
    const onChangeStory43Listener = (event) => {
        setstory43(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Haunted</h1>
                    <form>
                        <p className="ai-story-intro">Brian's adjustment to his new home has been difficult. He misses his friends, his old
                            home, and his dog. Then things take a turn for the worse when strange things start
                            happening in his house. Brian's bedroom window slams shut on its own, strange
                            shadows appear in the hallway, and things move in the closet. Brian is sure that the
                            house is haunted, but his parents don't believe him. With the help of his new friend,
                            Abby, Brian sets out to solve the mystery of the haunted house.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory43Listener} value={story43}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory43;

