import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";

const AIStory3 = () => {

// LocalStorage
    const [story3, setstory3] = useLocalStorageState("story3", "")
    // Save if changes are made
    const onChangeStory3Listener = (event) => {
        setstory3(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>A Zombie Survival Guide</h1>
                    <form>
                        <p className="ai-story-intro">Unlikely hero, Jeffery, lives a mundane life in the suburbs of
                            Columbus, Ohio. He mows lawns, delivers newspapers, and minds his own business. But when a
                            zombie apocalypse hits, Jeffery becomes separated from his family and must use his unique
                            skills to survive. With the help of his trusty dog, Duke, and his new friend, Brooke,
                            Jeffery braves the dangers of the undead and strives to find his family. </p>
                        <textarea className="ai-box" onChange={onChangeStory3Listener} value={story3}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory3;

