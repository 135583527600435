import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory13 = () => {

// LocalStorage
    const [story13, setstory13] = useLocalStorageState("story13", "")
    // Save if changes are made
    const onChangeStory13Listener = (event) => {
        setstory13(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Robot Adventures</h1>
                    <form>
                        <p className="ai-story-intro">Scout has always loved adventure stories and dreamed of one day going on one of her
                            own. So when she's chosen to participate in a top-secret robotics program, she can't
                            believe her good luck. The program is run by Dr. Jameson, a brilliant scientist who has
                            developed a team of advanced robots. Together, they will travel to the most dangerous
                            places on Earth, completing daring missions and fighting bad guys along the way. Scout
                            is excited to join the team, but she soon learns that being a robot adventurer is not as
                            easy as it seems. The robots are temperamental and often malfunction, requiring Scout to
                            think on her feet and quickly solve problems.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory13Listener} value={story13}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory13;

