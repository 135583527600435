import React from 'react';
import '../storybox.css';

const storyInput = ({onChange, onSubmit, value}) => {

    const handleChange = (e) => {
        onChange(e);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (value.length) {
            onSubmit(value)
        }
    }

    return (
        <form className="story-input" onSubmit={handleSubmit}>
            <textarea
                placeholder="TITLE &#13;Begin your story here.  Any stories you've saved can be found below.&#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;To enlarge your writing area, drag bottom right corner. &#13;Click Save to save your story to your local storage. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."
                onChange={handleChange} value={value}></textarea>
            <div className="save-align">
                <button className="save-btn">SAVE</button>
            </div>
        </form>
    )
}

export default storyInput;