import React from "react";
import {preloadImages} from './js/utils.js';
import {Grid} from './js/grid.js';
import "../../App.css";
import "./aiStories.css";
import "./css/importImages.css";
import "./js/index";
import {Link} from "react-router-dom";
import Fireflies from "../Fireflies/Fireflies";


function AIStories() {

// document.documentElement.className="js";var supportsCssVars=function(){var e,t=document.createElement("style");return t.innerHTML="root: { --tmp-var: bold; }",document.head.appendChild(t),e=!!(window.CSS&&window.CSS.supports&&window.CSS.supports("font-weight","var(--tmp-var)")),t.parentNode.removeChild(t),e};supportsCssVars()||alert("Please view this demo in a modern browser that supports CSS Variables.");
// Preload images then remove loader (loading class) from body
    preloadImages('.column__item-img').then(() => {
        document.body.classList.remove('loading');

        // Initialize the grid
        new Grid(document.querySelector('.columns'));
    });

    return (
        <>
            <Fireflies/>

            <div id="storyIdeas" style={{marginTop: "50px"}}>
                <div className="frame" style={{maxWidth: "20px"}}>
                    <Link to="/storyideas-index" reloadDocument={true} className="frame__title">Index of AI Stories to
                        Continue Writing</Link>
                </div>

                {/*Images 1-17*/}
                <div className="columns story-img-fadein" data-scroll-container="">
                    <div className="column-wrap column-wrap--height">
                        <div className="column">

                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={2}>
                                    <div className="column__item-img img1">
                                        <figcaption className="caption">
                                            <span>Wizardry</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>

                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={5}>
                                    <div className="column__item-img img2">
                                        <figcaption className="caption">
                                            <span>A Vampire's Story</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={8}>
                                    <div className="column__item-img img3">
                                        <figcaption className="caption">
                                            <span>Zombie Survival Guide</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={11}>
                                    <div className="column__item-img img4">
                                        <figcaption className="caption">
                                            <span>The Wild Frontier</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={14}>
                                    <div className="column__item-img img5">
                                        <figcaption className="caption">
                                            <span>Find the Killer</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={17}>
                                    <div className="column__item-img img6">
                                        <figcaption className="caption">
                                            <span>The Secret War</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={20}>
                                    <div className="column__item-img img7">
                                        <figcaption className="caption">
                                            <span>Land of the Elven</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={23}>
                                    <div className="column__item-img img8">
                                        <figcaption className="caption">
                                            <span>Unexpected Hero</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={26}>
                                    <div className="column__item-img img9">
                                        <figcaption className="caption">
                                            <span>Veil of the Dead</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={29}>
                                    <div className="column__item-img img10">
                                        <figcaption className="caption">
                                            <span>Off the Grid</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={32}>
                                    <div className="column__item-img img11">
                                        <figcaption className="caption">
                                            <span>Spy Games</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={35}>
                                    <div className="column__item-img img12">
                                        <figcaption className="caption">
                                            <span>Tale of a Shapeshifter</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={38}>
                                    <div className="column__item-img img13">
                                        <figcaption className="caption">
                                            <span>Robot Adventures</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={41}>
                                    <div className="column__item-img img14">
                                        <figcaption className="caption">
                                            <span>Just a Regular Guy</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={44}>
                                    <div className="column__item-img img15">
                                        <figcaption className="caption">
                                            <span>Mystical Arts</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={47}>
                                    <div className="column__item-img img16">
                                        <figcaption className="caption">
                                            <span>Hidden Away</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={50}>
                                    <div className="column__item-img img17">
                                        <figcaption className="caption">
                                            <span>The Witching Hour</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                        </div>
                        {/* /column */}
                    </div>

                    {/*Images 18 -34*/}
                    {/* /column-wrap */}
                    <div className="column-wrap">
                        <div className="column" data-scroll-section="">

                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={3}>
                                    <div className="column__item-img img18">
                                        <figcaption className="caption">
                                            <span>Proxima </span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={6}>
                                    <div className="column__item-img img19">
                                        <figcaption className="caption">
                                            <span>Time Traveler </span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={9}>
                                    <div className="column__item-img img20">
                                        <figcaption className="caption">
                                            <span>Suddenly a Spy </span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={12}>
                                    <div className="column__item-img img21">
                                        <figcaption className="caption">
                                            <span>Kingdom of Eshtaol  </span>
                                        </figcaption>
                                    </div>
                                </div>

                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={15}>
                                    <div className="column__item-img img22">
                                        <figcaption className="caption">
                                            <span>2048</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={18}>
                                    <div className="column__item-img img23">
                                        <figcaption className="caption">
                                            <span>Mind Reader</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={21}>
                                    <div className="column__item-img img24">
                                        <figcaption className="caption">
                                            <span>Jungle Fever</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={24}>
                                    <div className="column__item-img img25">
                                        <figcaption className="caption">
                                            <span>Reaching the Surface</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={27}>
                                    <div className="column__item-img img26">
                                        <figcaption className="caption">
                                            <span>Dark Hero</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={30}>
                                    <div className="column__item-img img27">
                                        <figcaption className="caption">
                                            <span>Making a Wish</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={33}>
                                    <div className="column__item-img img28">
                                        <figcaption className="caption">
                                            <span>Italian Secrets</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={36}>
                                    <div className="column__item-img img29">
                                        <figcaption className="caption">
                                            <span>Digging for Clues</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={39}>
                                    <div className="column__item-img img30">
                                        <figcaption className="caption">
                                            <span>Trouble in Paradise</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={42}>
                                    <div className="column__item-img img31">
                                        <figcaption className="caption">
                                            <span>Time of the Elves</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={45}>
                                    <div className="column__item-img img32">
                                        <figcaption className="caption">
                                            <span>Hidden Treasures</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={48}>
                                    <div className="column__item-img img33">
                                        <figcaption className="caption">
                                            <span>A Bloody Life</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={51}>
                                    <div className="column__item-img img34">
                                        <figcaption className="caption">
                                            <span>The Things I've Seen</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                        </div>
                        {/* /column */}
                        {/*Images 35-51*/}
                    </div>


                    <div className="column-wrap column-wrap--height">
                        <div className="column">
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={1}>
                                    <div className="column__item-img img35">
                                        <figcaption className="caption">
                                            <span>Elusive Hackers</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={4}>
                                    <div className="column__item-img img36">
                                        <figcaption className="caption">
                                            <span>2165</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={7}>
                                    <div className="column__item-img img37">
                                        <figcaption className="caption">
                                            <span>The Five Factions</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={10}>
                                    <div className="column__item-img img38">
                                        <figcaption className="caption">
                                            <span>Outrunning Trouble</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={13}>
                                    <div className="column__item-img img39">
                                        <figcaption className="caption">
                                            <span>Equestrian Magic</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={16}>
                                    <div className="column__item-img img40">
                                        <figcaption className="caption">
                                            <span>To the Underworld</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={19}>
                                    <div className="column__item-img img41">
                                        <figcaption className="caption">
                                            <span>A Getaway</span>
                                        </figcaption>
                                    </div>
                                </div>

                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={22}>
                                    <div className="column__item-img img42">
                                        <figcaption className="caption">
                                            <span>Floating Cities</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={25}>
                                    <div className="column__item-img img43">
                                        <figcaption className="caption">
                                            <span>Haunted</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={28}>
                                    <div className="column__item-img img44">
                                        <figcaption className="caption">
                                            <span>An Egyptian Mystery</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={31}>
                                    <div className="column__item-img img45">
                                        <figcaption className="caption">
                                            <span>Finding a New Planet</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={34}>
                                    <div className="column__item-img img46">
                                        <figcaption className="caption">
                                            <span>Australian Beginnings</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={37}>
                                    <div className="column__item-img img47">
                                        <figcaption className="caption">
                                            <span>An Inspector in Paris</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={40}>
                                    <div className="column__item-img img48">
                                        <figcaption className="caption">
                                            <span>Taking Over</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={43}>
                                    <div className="column__item-img img49">
                                        <figcaption className="caption">
                                            <span>Alaskan Adventures</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={46}>
                                    <div className="column__item-img img50">
                                        <figcaption className="caption">
                                            <span>Gold Rush</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                            <figure className="column__item">
                                <div className="column__item-imgwrap" data-pos={49}>
                                    <div className="column__item-img img51">
                                        <figcaption className="caption">
                                            <span>The Fate of the World</span>
                                        </figcaption>
                                    </div>
                                </div>
                            </figure>
                        </div>
                        {/* /column */}
                    </div>
                    {/* /column-wrap */}
                </div>
                {/* columns */}

                {/*Written Descriptions/Content*/}
                <div className="content">
                    <div className="content__item">
                        {/*<h2 className="content__item-title"></h2>*/}
                        <Link to="/story35" className="content__item-text" reloadDocument={true}>
                            <div className='condensed-text-xs'>My name's not important, and neither is my job. I'm just
                                a guy who usually ends up in the worst situations. You see, I'm a thrill seeker. I need
                                the rush of adrenaline to feel alive. And that's why I do the things I do. It also means
                                that I'm always one step away from disaster. I recently moved to Maui's north shore so I
                                could catch some killer
                                waves. As fate would have it, I met some other adrenaline junkies also into surfing.
                                Turns out they are cyber-criminals; they hack sites and disable things like power
                                grids and oil pipelines in order to get huge ransoms in cryptocurrency. It's how they
                                fund their adrenaline trips around the world. When they found out I was a programmer,
                                they asked me to join their team. Specifically, Ava asked me to join them. She is, by
                                far, the smartest and most beautiful hacker I've ever met. I figured I had two choices:
                                join up with them or leave Maui and never see them again. Turns out, I had a third
                                choice. I walked into my hotel room to find a man in a dark suit, standing in the corner
                                with his arms crossed. He offered me a job with the FBI. The bureau had been trying to
                                take down these cyber-criminals for years. But these hackers had managed to outsmart
                                every worldwide government agency. The FBI wanted
                                me to infiltrate their group and help take them down. Now that you know a little about
                                me, you can probably guess what I decided. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>

                    <div className="content__item">
                        <Link to="/story1" className="content__item-text" reloadDocument={true}>
                            <div>
                                I'm a wizard. I know, it doesn't sound like the most heroic thing in the world, but
                                trust me, it is. I've spent my life honing my skills, learning spells and mastering the
                                arcane arts. And it's all led to this moment, when I finally set out on my epic quest.
                                No, I'm not saving the world from a great evil (yet). I'm just looking for my missing
                                father. He was last seen in the Kingdom of Dorin, and that's where I'm headed.
                                <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>

                    <div className="content__item">
                        <Link to="/story18" className="content__item-text" reloadDocument={true}>
                            <div className="condensed-text">Leo had always dreamed of leaving Earth, but he never
                                imagined he would actually get the chance. That is, until he was chosen to join the
                                first human colony on the planet Proxima. Scientists called it a super-Earth with
                                everything needed to sustain human life. The assignment was a dream come true, but also
                                a daunting challenge. Proxima has a harsh environment and unpredictable weather. Every
                                day, the landscape changes as massive glaciers on the planet's equator move. They have
                                also encountered creatures who adapted to survive the constantly changing conditions. As
                                the colonists begin to build their new home, Leo quickly realizes that life on Proxima
                                is going to be anything but easy. He soon finds himself fighting for survival against
                                the elements and the native creatures. Luckily, he is not on his own. He befriends Tom,
                                someone who proves to be one of his closest friends. He also becomes close friends
                                Natasha, a one-woman assault force who will save his life more than once. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story36" className="content__item-text" reloadDocument={true}>
                            <div className='condensed-text'>In the year 2165, the world is a very different place. The
                                planet is a barren wasteland, the result of a cataclysmic event that took place in 2028.
                                The remaining humans have retreated into underground societies. They rarely venture out;
                                traveling by land is treacherous. But humanity has not given up. They have continued to
                                build and innovate, and now they have created something that could change everything:
                                robots. These robots are unlike anything the world has ever seen. They are smarter,
                                faster, and stronger than any human. One of the underground societies, known as the Ark,
                                has decided to send a team of robots on a trip across the dangerous landscape to help
                                humans colonize the surface. They are led by the meek, mild-mannered Kitaru and his
                                extremely ambitious, yet reckless partner, Shigeru.<span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story2" className="content__item-text" reloadDocument={true}>
                            <div>Many people think of vampires as creatures of the night, who stalk their prey in dark
                                alleys and lurk in shadows. But what if vampires were something else entirely? What if
                                they were like you and me? Meet Kaelyn, a young vampire who has just moved to a new
                                town. She's never been the best at making friends, but she's determined to fit in this
                                time. Kaelyn is different from other vampires in one very important way: she doesn't
                                drink blood. Her plan was to make a lot of friends and blend in. But her plan goes
                                sideways when she meets Jake and they fall head over heels for each other. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story19" className="content__item-text" reloadDocument={true}>
                            <div>Alice has a secret: she can time travel to any moment in history that she's ever wanted
                                to see. With her trusty time machine, the Tardis (a Doctor Who reference her
                                classmates don't get), Alice sets off on an epic adventure. She visits ancient Rome, the
                                height of the British Empire, and the Wild West. She meets historical figures like
                                Julius Caesar and Queen Victoria, and experiences first-hand the things they did and
                                saw. But as Alice travels, she begins to realize that her time machine has a dangerous
                                side effect. The more she uses it, the more she changes the past. When Alice
                                returns to her present day, her world is torn apart. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story37" className="content__item-text" reloadDocument={true}>
                            <div>In Beatrice Adler's world, society is divided into five factions, each dedicated to the
                                cultivation of a particular virtue: Candor (the honest), Abnegation (the selfless),
                                Dauntless (the brave), Amity (the peaceful), and Erudite (the intelligent). On her
                                sixteenth birthday, Beatrice must decide which faction she will devote her life to. Her
                                choice will shock her family and surprise her friends, but it is the first step in
                                discovering who she truly is. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story3" className="content__item-text" reloadDocument={true}>
                            <div>Unlikely hero, Jeffery, lives a mundane life in the suburbs of Columbus, Ohio. He mows
                                lawns, delivers newspapers, and minds his own business. But when a zombie apocalypse
                                hits, Jeffery becomes separated from his family and must use his unique skills to
                                survive. With the help of his trusty dog, Duke, and his new friend, Brooke, Jeffery
                                braves the dangers of the undead and strives to find his family. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story20" className="content__item-text" reloadDocument={true}>
                            <div className="condensed-text">My name is Layla, and I'm a spy. I'm not the kind of person
                                you would ever expect to be a spy and maybe that's why the UK's Secret Intelligence
                                Service recruited me. I was born
                                and raised in a small town in the middle of nowhere. I had a normal childhood, went to a
                                normal school, and had the normal dreams of going to college and getting a good job. But
                                then, when I was eighteen, I was recruited by the MI6 and accepted the job. I started
                                off with a fairly safe desk job intercepting secret messages sent by spies and foreign
                                leaders. Then one day, my boss, Nathaniel Addison, tells me that I'm being transferred
                                to a field position in Paris, France. It was unexpected and I didn't know what was
                                coming. Little did I know I would be sent on the most dangerous mission of my
                                career. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story38" className="content__item-text" reloadDocument={true}>
                            <div>I'm not sure how I got into this mess, but here I am, deep in the middle of a
                                nightmare. It all started with a phone call from my old friend, Connor. He said he
                                needed my help, and I couldn't say no. But I should have. Now I'm being chased by thugs
                                and
                                I have no idea why they're after me. I can only hope that I can figure out what's going
                                on
                                before it's too late. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story4" className="content__item-text" reloadDocument={true}>
                            <div>I’m Sophie and I'm just starting out on my own in the world. I’ve been raised on the
                                stories of cowboys and outlaws, and always wanted to see the West for myself. So
                                when I hear that my uncle has left me his ranch in his will, I decide to pack up my
                                horse and head out to Colorado. Little did I know that the ranch was in the middle of
                                nowhere, and that I’d be the only person for miles around. But that’s okay—I’m up for
                                the challenge. I soon discover that there’s more to the West than I expected. Soon I
                                find myself smack in the middle of an outlaw gang's turf...and I have to decide whether
                                to play by their rules. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>

                    <div className="content__item">
                        <Link to="/story21" className="content__item-text" reloadDocument={true}>
                            <div>The kingdom of Eshtaol was in peril. The evil wizard, Malric, had cast a spell that had
                                turned the king and queen into stone. The only hope for the kingdom was the young
                                prince, who had been sent away to live in safety. But when Malric learned of the
                                prince's whereabouts, he sent his minions to capture him. The prince narrowly escaped
                                and found himself in a strange and dangerous land. With the help of some new friends, he
                                set out to find a way to break the spell and save his parents. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story39" className="content__item-text" reloadDocument={true}>
                            <div>When Emma moves to the country to live with her grandparents, she expects to spend her
                                summer riding her horse, Belle, and helping out on the family farm. But she soon
                                discovers there's a lot more to running a farm than she realized. Her grandfather is
                                struggling to keep the farm afloat financially. What's more, her new friend,
                                Lucas, tells her about the magical creatures that live on the farm and the important
                                role they play in the farm's success. Emma is determined to help her grandfather, but
                                she quickly learns that it's not going to be easy. There is a corrupt investor
                                from the city who will do whatever he can to take the farm so he can sell the creatures
                                for a huge profit. But with Lucas's help, she just might be able to save the farm and
                                their way of
                                life. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story5" className="content__item-text" reloadDocument={true}>
                            <div>I’m not sure who I should be more worried about, the killer or the police. In the past
                                week, I’ve had two run-ins with the law. The first was when I was caught snooping around
                                the scene of the latest murder. The second was when I was found standing over the
                                victim’s body with the murder weapon in my hand. I swear I’m not the killer, but the
                                police aren’t so sure. I guess I’ll just have to find the real killer before they find
                                me. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story22" className="content__item-text" reloadDocument={true}>
                            <div className="condensed-text">By the year is 2048, ecological collapse, flooding, drought,
                                and severe food shortages have
                                left most of the world devastated. The rich have retreated to their luxury high-rise
                                apartments, while the poor scrabble for food and shelter. Sixteen-year-old Remi is a
                                street-wise orphan who has been scraping by since she was abandoned by her parents as a
                                baby. When Remi is offered a chance to go to school and find a better life, she takes
                                it. But it is not your average school. Students are being raised in artificial
                                intelligence classrooms. They have been genetically engineered to have IQ's over 350 and
                                microchip brain implants link their minds to a massive database. In seconds they can
                                download the knowledge required to fly a helicopter or build a bomb. A few rich people
                                have been hoarding what remains of dwindling resources while the poor are starving. No
                                one had a plan to save the world... until now. Remi finds herself on a journey that will
                                lead her to discover the fate of her parents and herself. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story40" className="content__item-text" reloadDocument={true}>
                            <div>Mythology is the story of gods and goddesses, heroes and monsters, love and tragedy. It
                                is a way to make sense of the world around us, to understand the unexplainable, and to
                                explore the depths of human experience. Myths offer us a way to see the world in a
                                different light, to confront our fears and doubts, and to find hope in the darkness. My
                                name is Persephone, and I am a daughter of Zeus. I was born to save the world, but I
                                didn't know it at the time. My story starts with a journey to the Underworld. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story6" className="content__item-text" reloadDocument={true}>
                            <div>In the summer of 1942, 15-year-old Anne-Marie Picard is eager to spend her vacation in
                                the south of France, where she and her family have a vacation home. But when the Nazis
                                invade and occupy France, Anne-Marie's vacation turns into a nightmare. She is forced to
                                flee her home and take refuge in the mountain village of Le Chambon, where the people
                                have taken a pledge to protect and hide Jews from the Nazis. With the help of her new
                                friends, Anne-Marie must find the courage to face the dangers of the war and survive
                                against all odds. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story23" className="content__item-text" reloadDocument={true}>
                            <div>Sophie is starting school in a new town. She's like any other
                                girl her age, trying to figure out where she fits in and what she wants to do with her
                                life. But Sophie has a secret: she can read people's thoughts. It's both a blessing and
                                a curse. She can't help but overhear other people's conversations, which she
                                often finds embarrassing. But Sophie is determined to use her special ability to help
                                others, and she's starting to figure out how to do just that. She's also made some new
                                friends, including the handsome and mysterious Liam. She doesn't know it yet, but he has
                                a secret too and things are about to get interesting. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story41" className="content__item-text" reloadDocument={true}>
                            <div>In a world of over-scheduled lives and crammed cities, Liza longs for a place to truly
                                escape. So when her great-aunt dies and leaves her an uninhabited island, she can't wait
                                to claim it as her own. With only her wits, her kayak, and her dog, Leo, Liza
                                starts to build her own paradise. But as she starts to make her island home, Liza
                                discovers that she's not alone. Someone else is there, watching her, and they're not
                                happy about her arrival. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story7" className="content__item-text" reloadDocument={true}>
                            <div className="condensed-text">Arin, a high elf, had always loved the forest. As a child,
                                she spent hours exploring the trees and the wildlife. She loved the way the sunlight
                                dappled through the leaves and the way the forest smelled of earth and pine. When she
                                was older, Arin began to learn about her people's history and magical abilities. She
                                learned about the great elven kingdoms that had once thrived in the forest until the
                                Master of the Dragons entered their realm and killed most of her people. One day, Arin
                                met a group of elves who were practicing their magic and they showed her how to use it
                                to help protect the
                                forest and its wildlife. Little did she know how important her new skills would soon
                                become. Early one morning, Arin woke to find enemy soldiers moving towards her village.
                                Her blood went cold. Then she saw an elf she had not seen in a long time and ran to him.
                                He looked as though he had seen a great deal of battle. He asked her if she would join
                                him in his quest to stop the Master of the Dragons and his army. Arin's heart was filled
                                with hope and she agreed without hesitation. She was ready to fight and win back the
                                forest
                                for her people.<span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story24" className="content__item-text" reloadDocument={true}>
                            <div>Hunter Morgan had always wanted to explore the Amazon jungle. So when he learned about
                                a new expedition that would be led by world-renowned explorer Dr. Lawrence Carter, he
                                knew he had to sign up. Hunter is excited for the adventure of a lifetime, but he has no
                                idea what he's in for. The jungle is a harsh and dangerous place, and the expedition
                                quickly turns into a nightmare. Hunter must use all of his skills and courage to survive
                                the treacherous journey. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story42" className="content__item-text" reloadDocument={true}>
                            <div>In a future world where the seas have risen and swallowed up much of the land, the last
                                humans live on floating cities built from the wreckage of the old world. For Jessie,
                                life on the floating city of New Chicago is a lonely one. With her mother dead and her
                                father always working, Jessie has no one to talk to and nothing to do but wander the
                                empty hallways of the city. But when a group of kids show up out of the blue,
                                Jessie finds herself with some unexpected, new friends. They're a ragtag
                                group of kids who live on a broken-down old ship called the Ransom, and they're on a
                                mission to find a way to fix their city. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story8" className="content__item-text" reloadDocument={true}>
                            <div>Ned is an unlikely hero. He spends most of his time fishing and training to be a
                                blacksmith. He's not the kind of person you'd ever expect to find in the middle of an
                                epic adventure. But when his quiet life is upended by the arrival of a mysterious girl,
                                Ned is thrust into a world of dragons, warriors, and a sorcerer who is bent on
                                destroying the world. He wishes he could go back to his quiet life, but he knows he must
                                find a way to stop the evil sorcerer, and he must do it before it's too late. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story25" className="content__item-text" reloadDocument={true}>
                            <div>As a mermaid, I have spent my entire life living beneath the waves. I have never seen
                                the land, nor do I know what it looks like. But that is all about to change. My father
                                has been chosen to lead our people on a voyage to the surface, and I am to accompany
                                him. I am nervous and excited at the same time. I have heard stories about the land
                                and I can't wait to see it for myself. I am also anxious about what I will find. Will it
                                be a beautiful, peaceful place? Or will it be a harsh, dangerous world? I am about to
                                find out. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story43" className="content__item-text" reloadDocument={true}>
                            <div> Brian's adjustment to his new home has been difficult. He misses his friends, his old
                                home, and his dog. Then things take a turn for the worse when strange things start
                                happening in his house. Brian's bedroom window slams shut on its own, strange
                                shadows appear in the hallway, and things move in the closet. Brian is sure that the
                                house is haunted, but his parents don't believe him. With the help of his new friend,
                                Abby, Brian sets out to solve the mystery of the haunted house. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story9" className="content__item-text" reloadDocument={true}>
                            <div> What if the laws of the universe started to unravel, and the veil between the living
                                and the dead began to thin? For fifteen year old Kira Moore, that's exactly what
                                happens. Kira has always been able to see the dead, but when her mother dies and she's
                                sent to live with her estranged father, she starts to see them more and more. At first,
                                she thinks that she's losing her mind, but she comes to realize that she's not the only
                                one seeing the dead. Neighbors and kids at her school report seeing
                                deceased family members and friends wandering the streets or standing outside the
                                homes they used to live in. Kira is unsure why so many ghosts are suddenly starting to
                                appear and what it means. She must learn to control her ability and use it to
                                help the town figure out what is going on.
                                <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story26" className="content__item-text" reloadDocument={true}>
                            <div>The city sleeps. The only sound that can be heard is the faint humming of the
                                streetlights. The occasional car drives down the road; the sound of the tires hitting
                                the asphalt is the only disruption to the silence. But the peace is short-lived. A
                                shadow
                                moves in the darkness, a figure leaping from rooftop to rooftop. The figure is faster
                                than any mortal. Soon the city is alive with the sound of sirens. This is not a creature
                                of nightmare, but a superhero, defending the innocent and saving the city from those who
                                would do harm. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story44" className="content__item-text" reloadDocument={true}>
                            <div>Neema has a knack for solving mysteries, so when a priceless artifact goes missing from
                                the ancient Egyptian exhibit at the Metropolitan Museum of Art, her detective instincts
                                are all abuzz. Joined by her best friend, the brainy and bibliophile Kesi, the two girls
                                sleuth their way through the crowded streets of Cairo, trying to track down the thief.
                                But they’re not the only ones on the case. The museum’s security team, led by the
                                dashing and mysterious Mr. Tarik, is also hot on the trail of the thief. As the clues
                                pile up, Neema and Kesi race against the clock to find the thief and save the day. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story10" className="content__item-text" reloadDocument={true}>
                            <div>When the small plane carrying humanitarian aid worker, Aaron Spencer, crashes in the
                                middle of the African jungle, he is sure he is doomed. With no signal for rescue,
                                Spencer must face the harsh elements of the jungle and the dangers of the wildlife
                                alone. As he forages for food and fights off predators, Spencer begins questioning
                                everything he thought he knew about himself and the world around him. Slowly, he comes
                                to understand that the jungle may be a harsh place, but it is also a place of beauty and
                                mystery. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story27" className="content__item-text" reloadDocument={true}>
                            <div>As the first rays of sunlight peek over the horizon, a young girl stirs in her bed. She
                                has a busy day ahead, with plenty of chores to do and lessons to learn. But before she
                                can get up, she has to say goodbye to her best friend. The girl closes her eyes and
                                makes a wish. "Please keep me safe, wherever you are." The girl opens her eyes and rolls
                                out of bed, ready to start her day. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story45" className="content__item-text" reloadDocument={true}>
                            <div>The year is 2060. Humanity has been forced to abandon earth after a devastating
                                cataclysm destroys the planet. But a new home is discovered in another solar system
                                close to
                                the earth and a team of brave
                                astronauts set out to explore it. They're not the only ones interested in this new
                                world though. Alien lifeforms are also on the hunt for it, and they'll stop at nothing
                                to get there first. It's up to the astronauts to discover the secrets of this new world
                                before the aliens do. Their team is led by the heroic Commander Veronica Reeves, and the
                                slightly nerdy but very brave Lieutenant Mark Benson. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story11" className="content__item-text" reloadDocument={true}>
                            <div>When I was in high school, the CIA recruited me. My mother's job took us around the
                                world which meant I was fluent in five languages. My parents didn't know I was a
                                genius at computer hacking and had an IQ of 240. But the CIA did. I was the youngest
                                person ever recruited by the CIA. I trained several hours a day in krav maga and
                                Brazilian jiu jitsu. They taught me how to hack into any system. I also learned how to
                                avoid being caught and how to lie convincingly. I've now been given my first assignment:
                                track down a missing fellow spy. His name is Logan. He is on the run after taking a
                                group of scientists hostage. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story28" className="content__item-text" reloadDocument={true}>
                            <div className="condensed-text">In the summer before 9th grade, Sophie reluctantly moves
                                from Seattle to a small town
                                in Tuscany, Italy to live with her grandparents. She's not happy about the move, but
                                she's
                                quickly won over by the beauty of the countryside and the warmth of her Italian family.
                                Sophie throws herself into the Italian lifestyle, learning to ride a Vespa, cook
                                regional cuisine, and speak the language. One summer morning, she takes a break from her
                                chores to explore a nearby valley and comes across a hidden cave. Inside, she discovers
                                a centuries-old painting of a young girl. Sophie is transfixed by the painting and can't
                                wait to tell her friends and family about it. But when she returns home that night, a
                                dark figure appears from behind a tree, grabs the painting, shoves her to the
                                ground and disappears into the darkness. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story46" className="content__item-text" reloadDocument={true}>
                            <div>It's not often that one gets a second chance at life, but for sixteen-year-old Cody,
                                that's exactly what he gets. After surviving life-threatening injuries from a car crash,
                                he's sent to live with his uncle in Australia. With a new start comes new opportunities
                                and challenges, and Cody is determined to make the most of his second chance. He quickly
                                makes friends at his new school and joins the surf club where he meets a girl named
                                Summer. Summer is determined to help Cody find his place in the world and show him that
                                there's more to Australia than just beaches and surfing. But when a dangerous criminal
                                from Cody's past comes looking for him, Cody has to put everything on the line to
                                protect the people he loves. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story12" className="content__item-text" reloadDocument={true}>
                            <div className="condensed-text">I am an eighteen-year-old girl who has been living in a
                                world of my own creation for as
                                long as I can remember. In my world, I am the only one who can see and interact with the
                                magical creatures that live there. My name is Jasmine and I am a shapeshifter. As a
                                shapeshifter I have the power to control the elements and to shape the land. I use my
                                powers to help the people who live in my world and protect them
                                from the creatures that would do them harm. I have always been content living in my own
                                world, but recently, I have started to feel like something is missing. I don't know what
                                it is, but I have a feeling I'm supposed to be doing something else, something
                                bigger. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story29" className="content__item-text" reloadDocument={true}>
                            <div>Mystery enthusiast Hayley Kincaid is used to digging for clues. So when her dad, a
                                private investigator, goes missing, she naturally takes on the case to track him down.
                                Along the way, she teams up with her best friend and a hunky, wisecracking
                                PI-in-training to follow the trail of clues. But as they get closer to finding her
                                father, they
                                find themselves in the middle of a dangerous conspiracy. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story47" className="content__item-text" reloadDocument={true}>
                            <div className="condensed-text">Inspector Thomas Andre is a young detective in the Paris
                                police department. He has seen
                                it all--or so he thought. When a gruesome murder occurs in the city's most prestigious
                                museum, Andre is called to the scene. The victim is a well-known art dealer, and the
                                suspect is a young woman with a troubling past. As Andre begins to piece together the
                                clues, he realizes that this is no ordinary case. The victim's family is wealthy,
                                influential, and not afraid to use their connections to get what they want.
                                Andre must navigate the politics of the art world as he races to find the killer. With
                                the help of his trusty sidekick, Gisele, he sets off to solve the case. But when the
                                intricate strands of a twisted crime are linked to something much darker, Andre
                                discovers that there are many in Paris willing to risk everything--even their lives--to
                                protect what they value. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story13" className="content__item-text" reloadDocument={true}>
                            <div>Scout has always loved adventure stories and dreamed of one day going on one of her
                                own. So when she's chosen to participate in a top-secret robotics program, she can't
                                believe her good luck. The program is run by Dr. Jameson, a brilliant scientist who has
                                developed a team of advanced robots. Together, they will travel to the most dangerous
                                places on Earth, completing daring missions and fighting bad guys along the way. Scout
                                is excited to join the team, but she soon learns that being a robot adventurer is not as
                                easy as it seems. The robots are temperamental and often malfunction, requiring Scout to
                                think on her feet and quickly solve problems. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story30" className="content__item-text" reloadDocument={true}>
                            <div className="condensed-text">It's been four days since the shipwreck and we are running
                                out of options. I was part
                                of a high school semester at sea program. Seventy-five of us were aboard the ship
                                making our way to Mauritius, when the storm hit. In the middle of the night I was jerked
                                awake and heard a loud, terrifying sound. The hull must have slammed into a rock. My
                                roommate, Josh, and I scrambled up the stairs to the top deck where the captain was
                                tossing life jackets to everyone and yelling for us to get into a dingy. Twelve of us
                                made
                                it to a deserted island. At least we think it's deserted. We were able to find some
                                fresh water, but food is scarce. Everyone has begun to give up hope, but I may have an
                                idea for how to save us. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story48" className="content__item-text" reloadDocument={true}>
                            <div>Most humans believe that if there is intelligent life out there in the universe, it
                                would be similar to us. But what if the aliens are nothing like us at all? What if they
                                are slimy, scaly creatures with razor sharp claws and teeth? And what if they want to
                                conquer Earth and enslave humanity? This is the world John Taylor must
                                face when he is taken captive by a group of aliens called the Skrell. The Skrell are a
                                cruel, heartless race and John is determined to find a way to escape from them. Along
                                the way, he meets other captives who help him in his quest, including a beautiful girl
                                named Jillian. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story14" className="content__item-text" reloadDocument={true}>
                            <div>I never meant to be a hero. I'm just a regular guy, trying to make my way in the world.
                                But sometimes, the world throws you a curve ball. And when that happens, you have to
                                swing for the fences. That's how I found myself in the middle of a madman's plot to take
                                over the world. I was just minding my own business when I was pulled into a web of
                                intrigue and danger. Now, I'm the only thing standing between the madman and his plans
                                for global domination. I'm not sure how I got here, but I know I can't back down now. I
                                have to see this through to the end, no matter what the cost. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story31" className="content__item-text" reloadDocument={true}>
                            <div>When the fate of the world rests on your shoulders, you can't afford to falter. As an
                                elf, I know this better than most. Our people have been under attack from evil forces
                                for
                                millenia. For years, I've been training for the day when I must face my ultimate enemy:
                                the dark lord of the underworld. His armies are vast and his powers are great, but I
                                will not back down. I am ready for this battle, and I know that I can win. The future of
                                my people and my world depends on it. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story49" className="content__item-text" reloadDocument={true}>
                            <div className='condensed-text'>Riley has always loved adventure. So when she's offered a
                                summer job leading kayak
                                tours in the rugged backcountry of Alaska, she jumps at the chance. Riley quickly learns
                                that the playful beauty of the northern lights is in stark contrast to the vast and
                                isolated landscape full of danger. She must navigate Class IV rapids, avoid grizzly
                                bears, and make her way through the dense wilderness. But the biggest danger of all is
                                the one she doesn't see coming. A powerful storm hits the area, leaving Riley and her
                                group stranded in the middle of nowhere. With no way to signal for help, they must find
                                their way back to civilization before they run out of food and supplies. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story15" className="content__item-text" reloadDocument={true}>
                            <div>When Calum Claymore enrolls in the prestigious Pembroke School for Mystical Arts, he
                                had no idea what he was getting himself into. A hidden world is waiting for him, full of
                                magical creatures and treacherous secrets. As Calum begins to uncover the mysteries of
                                the school, he discovers that he has a special gift - the ability to summon mystical
                                creatures known as elementals. With the help of his new friends, Fiona and Rowan, Calum
                                must learn to control his power and stop an evil force from taking over the
                                school. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story32" className="content__item-text" reloadDocument={true}>
                            <div>Quinn has always been the type of guy to take risks and push boundaries, which has led
                                to more than his fair share of scrapes and close calls. But at least he's never bored.
                                When Quinn learns that his estranged father has died, he figures it's time to go home,
                                settle the estate, and move on with his life. What Quinn doesn't know is that his father
                                has left him a cryptic message that leads him on a wild adventure to uncover a hidden
                                treasure. With the help of a beautiful stranger, Quinn must dodge traps, outsmart
                                enemies, and solve puzzles that will take him to the most exciting places he's ever
                                been. This is one adventure he's never going to forget. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story50" className="content__item-text" reloadDocument={true}>
                            <div>The year is 1848 and thirteen-year-old Claire is determined to find her place in the
                                world. She's grown up on a small farm with her parents and four brothers, and she's
                                eager to make her mark. When she learns of the California Gold Rush, she knows that's
                                where she needs to be. With her parents' permission, she sets off for the West Coast.
                                Claire is tough and not afraid of a little hard work. She's also quick to make friends
                                and soon finds herself in the company of other gold miners. Despite the challenges of
                                the journey and the dangers of the gold fields, Claire is determined to make her
                                fortune. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story16" className="content__item-text" reloadDocument={true}>
                            <div>I agreed to help my old friend, Nick, move some boxes in his storage unit. That was my
                                first mistake and unfortunately, not my last one. There, in the back corner, was a
                                large, old steamer trunk. Nick told me he had found it at an auction and had been
                                waiting for the right time to open it. Curiosity got the best of me, so I offered to
                                help him. I could have never predicted what would happen. The moment he opened it, I
                                knew it was the biggest mistake of our lives. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story33" className="content__item-text" reloadDocument={true}>
                            <div>I was born into a life of gang violence. It was all I knew. My father was a gang member
                                and my earliest memories are of seeing my father with blood all over his face. I was too
                                young to understand what was going on, but I knew it was bad. My mother would always
                                tell me that my father was a "bad guy." I never knew what that meant, but I knew it was
                                something to be afraid of. As I got older, I began to understand what was going on. My
                                father was in a gang and he was always getting into fights with other gangs. I was
                                scared for him, but I also wanted to be like him. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story51" className="content__item-text" reloadDocument={true}>
                            <div className="condensed-text">I am a citizen of the United States of America. I was born
                                in the year 2035, in the city of Houston, Texas. At the time, it was the most populous
                                city in the country. Now, it's one of the last remaining strongholds of humanity. We've
                                been fighting for years, against the machines that have taken over our planet. I've been
                                told that the sky used to be blue. Now it is blood orange and the air is thick with
                                pollution. The Earth is dying and so are we. The crops are withered and barren, the
                                water is poisoned and the animals are gone. But we don't give up easily. We've been
                                fighting for our lives, for our future. Today, the fate of the entire human species is
                                in our hands. If we succeed, the machines will perish and life as we know it will be
                                over. If we fail, we will face extinction.<span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story17" className="content__item-text" reloadDocument={true}>
                            <div>When Emerson Watts learns she’s a witch, her entire world is turned upside down. As she
                                begins to understand her newfound powers, she also starts to uncover secrets about her
                                family – secrets that have been kept for centuries. Joined by her best friend, Cat, and
                                a charming young wizard named Leo, Emerson sets out to uncover the truth. They soon
                                discover that Emerson is a key player in a deadly game that has been going on for
                                centuries. A game that could have disastrous consequences for the world. <span
                                    className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>
                    <div className="content__item">
                        <Link to="/story34" className="content__item-text" reloadDocument={true}>
                            <div>I'm not like other people. I can see things that others can't--ghosts, spirits, and
                                other supernatural creatures. It's not that I'm cursed or special, it's just the way I
                                am. And I've learned to live with it. But when my mom dies, I have to move to a small
                                town in Oklahoma to live with my grandparents. At first, I'm excited to be living in the
                                same house as my mom did as a child.  Soon, however, I realize that the house is haunted. And
                                not just by regular ghosts, but by evil spirits who want to kill me. But I'm not going
                                to let them win. I'm going to fight back and figure out what happened to my mom and
                                why these spirits are targeting me. <span className="select-story"> Select this story to continue writing it.</span>
                            </div>
                        </Link>
                    </div>

                    <nav className="content__nav">
                        <div className="content__nav-wrap">
                            <div className="content__nav-item img1"/>
                            <div className="content__nav-item img2"/>
                            <div className="content__nav-item img3"/>
                            <div className="content__nav-item img4"/>
                            <div className="content__nav-item img5"/>
                            <div className="content__nav-item img6"/>
                            <div className="content__nav-item img7"/>
                            <div className="content__nav-item img8"/>
                            <div className="content__nav-item img9"/>
                            <div className="content__nav-item img10"/>
                            <div className="content__nav-item img11"/>
                            <div className="content__nav-item img12"/>
                            <div className="content__nav-item img13"/>
                            <div className="content__nav-item img14"/>
                            <div className="content__nav-item img15"/>
                            <div className="content__nav-item img16"/>
                            <div className="content__nav-item img17"/>
                            <div className="content__nav-item img21"/>
                            <div className="content__nav-item img22"/>
                            <div className="content__nav-item img23"/>
                            <div className="content__nav-item img24"/>
                            <div className="content__nav-item img25"/>
                            <div className="content__nav-item img26"/>
                            <div className="content__nav-item img27"/>
                            <div className="content__nav-item img28"/>
                            <div className="content__nav-item img29"/>
                            <div className="content__nav-item img30"/>
                            <div className="content__nav-item img31"/>
                            <div className="content__nav-item img32"/>
                            <div className="content__nav-item img33"/>
                            <div className="content__nav-item img34"/>
                            <div className="content__nav-item img35"/>
                            <div className="content__nav-item img36"/>
                            <div className="content__nav-item img37"/>
                            <div className="content__nav-item img38"/>
                            <div className="content__nav-item img39"/>
                            <div className="content__nav-item img40"/>
                            <div className="content__nav-item img41"/>
                            <div className="content__nav-item img42"/>
                            <div className="content__nav-item img43"/>
                            <div className="content__nav-item img44"/>
                            <div className="content__nav-item img45"/>
                            <div className="content__nav-item img46"/>
                            <div className="content__nav-item img47"/>
                            <div className="content__nav-item img48"/>
                            <div className="content__nav-item img49"/>
                            <div className="content__nav-item img50"/>
                            <div className="content__nav-item img51"/>

                        </div>
                    </nav>

                    <button className="unbutton button-back">
                        <svg viewBox="0 0 50 9" width="100%">
                            <path d="M0 4.5l5-3M0 4.5l5 3M50 4.5h-77"/>
                        </svg>
                    </button>
                </div>
            </div>
        </>
    )
}

export default AIStories