import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory49 = () => {

// LocalStorage
    const [story49, setstory49] = useLocalStorageState("story49", "")
    // Save if changes are made
    const onChangeStory49Listener = (event) => {
        setstory49(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Alaskan Adventures</h1>
                    <form>
                        <p className="ai-story-intro">Riley has always loved adventure. So when she's offered a summer
                            job leading kayak tours in the rugged backcountry of Alaska, she jumps at the chance. Riley
                            quickly learns that the playful beauty of the northern lights is in stark contrast to the
                            vast and isolated landscape full of danger. She must navigate Class IV rapids, avoid grizzly
                            bears, and make her way through the dense wilderness. But the biggest danger of all is the
                            one she can't see coming. A powerful storm hits the area, leaving Riley and her group
                            stranded in the middle of nowhere. With no way to signal for help, they must find their way
                            back to civilization before they run out of food and supplies.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory49Listener} value={story49}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory49;

