import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory33 = () => {

// LocalStorage
    const [story33, setstory33] = useLocalStorageState("story33", "")
    // Save if changes are made
    const onChangeStory33Listener = (event) => {
        setstory33(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="max1024">
                    <div className="ai-container">
                        <h1>A Bloody Life</h1>
                        <form>
                            <p className="ai-story-intro">I was born into a life of gang violence. It was all I knew. My
                                father was a gang member and my earliest memories are of seeing my father with blood all
                                over his face. I was too young to understand what was going on, but I knew it was bad.
                                My
                                mother would always tell me that my father was a "bad guy." I never knew what that
                                meant,
                                but I knew it was something to be afraid of. As I got older, I began to understand what
                                was
                                going on. My father was in a gang and he was always getting into fights with other
                                gangs. I
                                was scared for him, but I also wanted to be like him.
                            </p>
                            <textarea className="ai-box" onChange={onChangeStory33Listener} value={story33}
                                      placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                        </form>
                    </div>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory33;

