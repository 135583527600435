import React from "react";
import {createChatBotMessage} from "react-chatbot-kit";
import LearningOptions from "./LearningOptions/LearningOptions";
import LinkList from "./LinkList/LinkList";

import { HashLink } from 'react-router-hash-link';


const config = {
    botName: "Mage",
    initialMessages: [
        createChatBotMessage("How can I help?",

            {
                widget: "learningOptions",
            }),
        // createChatBotMessage("You can also text me what you're looking for."),

    ],

    customStyles: {
        botMessageBox: {
            backgroundColor: "#376B7E",
        },
        chatButton: {
            backgroundColor: "#376B7E",
        },
    },

    widgets: [
        {
            widgetName: "learningOptions",
            widgetFunc: (props) => <LearningOptions {...props} />,
        },

        // {
        //     widgetName: "GPTHelpLinks",
        //     widgetFunc: (props) => <LinkList {...props} />,
        //     props: {
        //         options: [
        //             {
        //                 text: "opening paragraph",
        //                 url:
        //                     "/introduction",
        //                 id: 1,
        //             },
        //             {
        //                 text: "title",
        //                 url: "/title",
        //                 id: 2,
        //             },
        //             {
        //                 text: "character description",
        //                 url: "/character",
        //                 id: 3,
        //             },
        //             {
        //                 text: "setting",
        //                 url: "/setting",
        //                 id: 4,
        //             },
        //             {
        //                 text: "see a full list of AI help topics",
        //                 url: "/gpt-help-index",
        //                 id: 5,
        //             },
        //         ],
        //     },
        // },
        {
            widgetName: "storyConcept",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Create a rock-solid story concept, develop characters, and build a captivating story world. Read more...",
                        url:
                            "/writing101#storyConcept",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "startWriting",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Create a story outline",
                        url:
                            "/story-outline",
                        id: 1,
                    },
                    {
                        text: "Begin writing your story",
                        url:
                            "/my-stories",
                        id: 2,
                    },
                    {
                        text: "Find a story you already started",
                        url:
                            "/my-stories",
                        id: 3,
                    },
                    {
                        text: "Find an AI created story idea",
                        url:
                            "/storyideas-index",
                        id: 4,
                    },
                ],
            },
        },
        {
            widgetName: "findStory",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "find the story you're currently working on",
                        url:
                            "/my-stories",
                        id: 1,
                    },
                    {
                        text: "continue writing a story you found in the AI story gallery",
                        url:
                            "/storyideas-index",
                        id: 2,
                    },
                ],
            },
        },
        {
            widgetName: "learnToWrite",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Writing 101",
                        url:
                            "/writing101",
                        id: 1,
                    },
                    {
                        text: "How to write a Story Outline",
                        url:
                            "/story-outline",
                        id: 2,
                    },
                    {
                        text: "Find a good Story Idea",
                        url:
                            "/story-ideas",
                        id: 3,
                    },

                ],
            },
        },
        {
            widgetName: "storyIdea",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Explore over 50 prompts that will spark your imagination.  Or, you can choose a story you like and write the ending.",
                        url:
                            "/story-ideas",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "genre",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Other popular genres and subgenres include young adult, action-adventure, romance, biographical novel, gothic, historical fiction, horror, psychological, romance, whodunit mystery, amateur detective, suspense, police procedural, espionage, medical thriller, courtroom drama, comedy, romantic comedy, paranormal, Christian and LGBTQ+. Read more...",

                        url:
                            "/writing101#genre",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "character",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Create compelling characters with unique personalities, motivations, and quirks that will draw readers into your story.",

                        url:
                            "/writing101#character",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "introduction",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Intro paragraph = movie trailer! Squeeze mystery, action, or a quirky character into 1-3 sentences. Hook the reader and leave them begging for more! Read more...",
                        url:
                            "/writing101#intro",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "name",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "have AI give me name ideas for my characters",
                        url:
                            "/name",
                        id: 1,
                    },
                ],
            },
        },

        {
            widgetName: "other",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Need AI to help you write something?",
                        url:
                            "/other",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "plot",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "A plot should drive conflict, suspense, and a truly unforgettable experience. Read more...",
                        url:
                            "/writing101#plot",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "next",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "AI to help you move your story along and/or write your next paragraph?",
                        url:
                            "/next-paragraph",
                        id: 1,
                    },
                    {
                        text: "to learn more about how to develop your story?",
                        url:
                            "/writing101",
                        id: 2,
                    },
                ],
            },
        },
        {
            widgetName: "title",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "AI can help you create a title for your story",
                        url:
                            "/title",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "pov",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Every story has a perspective. Discover how to pick the perfect point of view to shape your narrative and create a captivating narrative.",
                        url:
                            "/writing101#pov",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "hook",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "The Hook Explained: Why It Matters for Your Story. Read more...",
                        url:
                            "/writing101#hook",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "grammar",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "some help with proofreading your story?",
                        url:
                            "/grammar",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "dialogue",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "AI can help you with writing a conversation",
                        url:
                            "/dialogue",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "aistories",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Hope you like them as much as I do!",
                        url:
                            "/story-ideas",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "setting",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Setting isn't just scenery. It's where the magic happens. Learn how to build a world that immerses readers and fuels your story.",
                        url:
                            "/writing101#setting",
                        id: 1,
                    },
                ],
            },
        },
        {
            widgetName: "prompts",
            widgetFunc: (props) => <LinkList {...props} />,
            props: {
                options: [
                    {
                        text: "Explore over 50 prompts that will spark your imagination.",
                        url:
                            "/story-ideas",
                        id: 1,
                    },
                ],
            },
        },
    ],
};

export default config;
