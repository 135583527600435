import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory4 = () => {

// LocalStorage
    const [story4, setstory4] = useLocalStorageState("story4", "")
    // Save if changes are made
    const onChangeStory4Listener = (event) => {
        setstory4(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="max1024">
                    <div className="ai-container">
                        <h1>Italian Secret</h1>
                        <form>
                            <p className="ai-story-intro">In the summer before 9th grade, Sophie reluctantly moves
                                from Seattle to a small town
                                in Tuscany, Italy to live with her grandparents. She's not happy about the move, but
                                she's
                                quickly won over by the beauty of the countryside and the warmth of her Italian family.
                                Sophie throws herself into the Italian lifestyle, learning to ride a Vespa, cook
                                regional cuisine, and speak the language. One summer morning, she takes a break from her
                                chores to explore a nearby valley and comes across a hidden cave. Inside, she discovers
                                a centuries-old painting of a young girl. Sophie is transfixed by the painting and can't
                                wait to tell her friends and family about it. But when she returns home that night, a
                                dark figure appears from behind a tree, grabs the painting, shoves her to the
                                ground and disappears into the darkness.
                            </p>
                            <textarea className="ai-box" onChange={onChangeStory4Listener} value={story4}
                                      placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                        </form>
                    </div>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory4;

