import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory17 = () => {

// LocalStorage
    const [story17, setstory17] = useLocalStorageState("story17", "")
    // Save if changes are made
    const onChangeStory17Listener = (event) => {
        setstory17(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>The Witching Hour</h1>
                    <form>
                        <p className="ai-story-intro">When Emerson Watts learns she’s a witch, her entire world is
                            turned upside down. As she begins to understand her newfound powers, she also starts to
                            uncover secrets about her family – secrets that have been kept for centuries. Joined by her
                            best friend, Cat, and a charming young wizard named Leo, Emerson sets out to uncover the
                            truth. They soon discover that Emerson is a key player in a deadly game that has been going
                            on for centuries. A game that could have disastrous consequences for the world.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory17Listener} value={story17}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory17;

