import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";

const AIStory7 = () => {

// LocalStorage
    const [story7, setstory7] = useLocalStorageState("story7", "")
    // Save if changes are made
    const onChangeStory7Listener = (event) => {
        setstory7(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Land of the Elven</h1>
                    <form>
                        <p className="ai-story-intro">Arin, a high elf, had always loved the forest. As a child,
                            she spent hours exploring the trees and the wildlife. She loved the way the sunlight
                            dappled through the leaves and the way the forest smelled of earth and pine. When she
                            was older, Arin began to learn about her people's history and magical abilities. She
                            learned about the great elven kingdoms that had once thrived in the forest until the
                            Master of the Dragons entered their realm and killed most of her people. One day, Arin
                            met a group of elves who were practicing their magic and they showed her how to use it to help protect the
                            forest and its wildlife. Little did she know how important her new skills would soon
                            become. Early one morning, Arin woke to find enemy soldiers moving towards her village.
                            Her blood went cold. Then she saw an elf she had not seen in a long time and ran to him.
                            He looked as though he had seen a great deal of battle. He asked her if she would join
                            him in his quest to stop the Master of the Dragons and his army. Arin's heart was filled
                            with hope and she agreed without hesitation. She was ready to fight and win back the forest
                            for her people.</p>
                        <textarea className="ai-box" onChange={onChangeStory7Listener} value={story7}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory7;

