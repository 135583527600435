import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory35 = () => {

// LocalStorage
    const [story35, setstory35] = useLocalStorageState("story35", "")
    // Save if changes are made
    const onChangeStory35Listener = (event) => {
        setstory35(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Elusive Hackers</h1>
                    <form>
                        <p className="ai-story-intro">My name's not important, and neither is my job. I'm just
                            a guy who usually ends up in the worst situations. You see, I'm a thrill seeker. I need
                            the rush of adrenaline to feel alive. And that's why I do the things I do. It also means
                            that I'm always one step away from disaster. I recently moved to Maui's north shore so I
                            could catch some killer waves. As fate would have it, I met some other adrenaline junkies
                            also into surfing. Turns out they are cyber-criminals; they hack sites and disable things like power
                            grids and oil pipelines in order to get huge ransoms in cryptocurrency. It's how they
                            fund their adrenaline trips around the world. When they found out I was a programmer,
                            they asked me to join their team. Specifically, Ava asked me to join them. She is, by
                            far, the smartest and most beautiful hacker I've ever met. I figured I had two choices:
                            join up with them or leave Maui and never see them again. Turns out, I had a third
                            choice. I walked into my hotel room to find a man in a dark suit, standing in the corner
                            with his arms crossed. He offered me a job with the FBI. The bureau had been trying to
                            take down these cyber-criminals for years. But these hackers had managed to outsmart
                            every worldwide government agency. The FBI wanted me to infiltrate their group and help take
                            them down. Now that you know a little about me, you can probably guess what I decided.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory35Listener} value={story35}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory35;

