import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory38 = () => {

// LocalStorage
    const [story38, setstory38] = useLocalStorageState("story38", "")
    // Save if changes are made
    const onChangeStory38Listener = (event) => {
        setstory38(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Outrunning Trouble</h1>
                    <form>
                        <p className="ai-story-intro">I'm not sure how I got into this mess, but here I am, deep in the middle of a
                            nightmare. It all started with a phone call from my old friend, Connor. He said he
                            needed my help, and I couldn't say no. But I should have. Now I'm being chased by thugs and
                            I have no idea why they're after me. I can only hope that I can figure out what's going on
                            before it's too late.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory38Listener} value={story38}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory38;

