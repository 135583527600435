import React from "react";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import './storyOutline.css'
import ChatBot from "../../Chatbot/Chatbot";


function useLocalStorageState(key, defaultValue = "") {
    const [state, setState] = React.useState(
        () => window.localStorage.getItem(key) || defaultValue
    )
    React.useEffect(() => {
        window.localStorage.setItem(key, state)
    }, [state, key])
    return [state, setState]
}

const Outline = () => {

// LocalStorage
    const [title, setTitle] = useLocalStorageState("title", "")
    const [concept, setconcept] = useLocalStorageState("concept", "")
    const [genre, setgenre] = useLocalStorageState("genre", "")
    const [pov, setpov] = useLocalStorageState("pov", "")
    const [protagonist, setprotagonist] = useLocalStorageState("protagonist", "")
    const [antagonist, setantagonist] = useLocalStorageState("antagonist", "")
    const [characters, setcharacters] = useLocalStorageState("characters", "")
    const [setting, setsetting] = useLocalStorageState("setting", "")
    const [introduction, setintroduction] = useLocalStorageState("introduction", "")
    const [act1, setact1] = useLocalStorageState("act1", "")
    const [act2, setact2] = useLocalStorageState("act2", "")
    const [act3, setact3] = useLocalStorageState("act3", "")

    // Save if changes are made
    const onChangeTitleListener = (event) => {
        setTitle(event.target.value)
    }
    const onChangeConceptListener = (event) => {
        setconcept(event.target.value)
    }
    const onChangeGenreListener = (event) => {
        setgenre(event.target.value)
    }
    const onChangePOVListener = (event) => {
        setpov(event.target.value)
    }
    const onChangeProtagonistListener = (event) => {
        setprotagonist(event.target.value)
    }
    const onChangeAntagonistListener = (event) => {
        setantagonist(event.target.value)
    }
    const onChangeCharactersListener = (event) => {
        setcharacters(event.target.value)
    }
    const onChangeSettingListener = (event) => {
        setsetting(event.target.value)
    }
    const onChangeIntroductionListener = (event) => {
        setintroduction(event.target.value)
    }
    const onChangeAct1Listener = (event) => {
        setact1(event.target.value)
    }
    const onChangeAct2Listener = (event) => {
        setact2(event.target.value)
    }
    const onChangeAct3Listener = (event) => {
        setact3(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="writingcontainer">
                    <div className="outline1024">
                        <h1>Story Outline</h1>
                        <p>Many writers compare their story outline to the blueprint for building a house. For others
                            it's more like a road map for getting to a far-off destination. It helps writers
                            stay focused on how the plot and characters develop. Your story outline should be detailed
                            enough that anyone reading it would be able to clearly imagine your characters, setting, and
                            how the main events will unfold.</p>

                        <form>
                            <p className="outline-sm-caps">Title</p><textarea className="txtoutline sm-box"
                                                                           onChange={onChangeTitleListener}
                                                                           value={title}
                                                                           placeholder="Write a title for your story that will intrigue readers."/>
                            <p className="outline-sm-caps">Story Concept</p><textarea className="txtoutline md-box"
                                                                                  onChange={onChangeConceptListener}
                                                                                  value={concept}
                                                                                  placeholder="Write the main concept that sums up your story (the core idea).  If you were trying to sell your book, it would be your pitch and should be no more than 1-4 sentences."/>

                            <p className="outline-sm-caps">Genre</p><textarea className="txtoutline sm-box"
                                                                           onChange={onChangeGenreListener}
                                                                           value={genre}
                                                                           placeholder="Which genre interests you the most? Action-adventure, biographical novel, gothic, historical fiction, horror, mystery, psychological, romance, science fiction, fantasy, thriller, whodunit mystery, amateur detective, suspense, police procedural, espionage, medical thriller, courtroom drama, comedy, romantic comedy, dystopian, paranormal, and Christian. "/>
                            <p className="outline-sm-caps">Point of View</p><textarea className="txtoutline sm-box"
                                                                                   onChange={onChangePOVListener}
                                                                                   value={pov}
                                                                                   placeholder="Point of view is the voice of your story. Using I or WE is first person.  It can be the easiest voice to write in, but it can be limiting because you can't closely follow any other characters. Using YOU is second person point of view. Second person allows you to speak to the reader directly, but it is quirky and rarely used.  Third person uses HE or SHE. The third-person omniscient point of view tells the thoughts and feelings of all the characters and can instantly transport the reader to any setting within the story. "/>
                            <p className="outline-sm-caps">Protagonist</p><textarea className="txtoutline md-box"
                                                                                 onChange={onChangeProtagonistListener}
                                                                                 value={protagonist}
                                                                                 placeholder="Who is the main character?  Is your protagonist ordinary or extraordinary?  How does this character respond to the problem or conflict? What is their objective (i.e. what do they want or hope to accomplish in their journey)?  Create a protagonist your readers will find interesting. If they don't care about the main character, they won't care about the story either."/>
                            <p className="outline-sm-caps">Antagonist</p><textarea className="txtoutline md-box"
                                                                                onChange={onChangeAntagonistListener}
                                                                                value={antagonist}
                                                                                placeholder="Who is the main character's opponent? Who or what stands in the way of your protagonist achieving their goal?  What is the antagonist's motive?  Do they have a backstory that makes their actions more credible?  In Lord of the Rings, Sauron dreams of world domination and will do anything for it. He is more than just an evil villain, however. He is a worthy antagonist because he actively opposes Frodo and everyone in the fellowship of the ring. "/>
                            <p className="outline-sm-caps">Characters</p><textarea className="txtoutline md-box"
                                                                                onChange={onChangeCharactersListener}
                                                                                value={characters}
                                                                                placeholder="Supporting Characters often help the main character achieve their goals.  For example Ron, Hermione and Luna in the Harry Potter Series help Harry.  And in doing so, readers love them, root for them, and feel upset when they're harmed. Each character also has distinctive traits that make them unique. "/>
                            <p className="outline-sm-caps">Setting</p><textarea className="txtoutline md-box"
                                                                             onChange={onChangeSettingListener}
                                                                             value={setting}
                                                                             placeholder="Readers must be able to clearly imagine the setting for a story to come to life.  Choose a place and time you find exciting.  Ideally, it feels so real they believe the place really exists, at least while they are reading your story.  The goal is to create a world you would want to spend a lot of time in."/>
                            <p className="outline-sm-caps">Hook</p><textarea className="txtoutline md-box"
                                                                          onChange={onChangeIntroductionListener}
                                                                          value={introduction}
                                                                          placeholder="Your story should begin with a hook that grabs your reader's attention.  For example, Harry Potter begins with the following sentence: Mr. and Mrs. Dursley of number four, Privet Drive, were proud to say that they were perfectly normal, thank you very much."/>
                            <p className="outline-sm-caps">Act One</p><textarea className="txtoutline md-box"
                                                                             onChange={onChangeAct1Listener}
                                                                             value={act1}
                                                                             placeholder="Stories usually begin with the main character living a fairly boring life (status quo) .  Then something sparks a conflict that changes everything (inciting incident).  For example, when Harry Potter is a baby his parents are murdered and he must live with the Durley's who are cruel.  They try to keep him from attending the Hogwarts School of Witchcraft and Wizardry when he turns 11. But Hagrid tracks Harry down and takes him to the school to begin his training and adventure."/>
                            <p className="outline-sm-caps">Act Two</p><textarea className="txtoutline md-box"
                                                                             onChange={onChangeAct2Listener}
                                                                             value={act2}
                                                                             placeholder="One or more events happen that complicate the situation. The main character confronts challenges that force them to grow and change. For example, in The Sorceror's Stone, Harry and his new friends must fight a troll, play a dangerous game of quidditch, and escape from Voldemort in the forest.  These sparks keep the ball rolling and are important to Harry's development as a hero."/>
                            <p className="outline-sm-caps">Act Three</p><textarea className="txtoutline md-box"
                                                                               onChange={onChangeAct3Listener}
                                                                               value={act3}
                                                                               placeholder="In this final act, the stakes are high, but the problem is solved and all loose ends are tied up.  Harry and his friends sneak past a 3 headed dog and overcome obstacles to uncover the secret being guarded by the school.  Then alone, Harry must face the evil wizard who killed his parents, Voldemort.  He obtains the Philosophers Stone, kills Professor Quirrel and Voldemort becomes powerless. Harry and his friends are rewarded at Hogwarts ceremony for their courageous acts and he returns to the Durley's forever changed."/>
                        </form>

                        {/*<p>If you prefer to fill this out using pen and paper, you can print a copy instead.</p>*/}

                    </div>
                </div>
            </div>
            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            /></>
    )
}

export default Outline