import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory14 = () => {

// LocalStorage
    const [story14, setstory14] = useLocalStorageState("story14", "")
    // Save if changes are made
    const onChangeStory14Listener = (event) => {
        setstory14(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Just a Regular Guy</h1>
                    <form>
                        <p className="ai-story-intro">I never meant to be a hero. I'm just a regular guy, trying to make my way in the world.
                            But sometimes, the world throws you a curve ball. And when that happens, you have to
                            swing for the fences. That's how I found myself in the middle of a madman's plot to take
                            over the world. I was just minding my own business when I was pulled into a web of
                            intrigue and danger. Now, I'm the only thing standing between the madman and his plans
                            for global domination. I'm not sure how I got here, but I know I can't back down now. I
                            have to see this through to the end, no matter what the cost.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory14Listener} value={story14}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory14;

