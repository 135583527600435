import React from "react";
import "../App.css"
import "./home.css";
import {Link} from "react-router-dom";
import ChatBot from "./Chatbot/Chatbot";
import Fireflies from "./Fireflies/Fireflies";
function Home() {

    const one = document.getElementById('one');
    const cover = document.getElementById('cover');
    const filling = document.getElementById('filling');
    window.addEventListener('scroll', () => {
        let scrollY = window.scrollY;
        let bottomHeight = window.innerHeight;

        if (scrollY / bottomHeight <= 1) {
            one.style.opacity = '1 - ( scrollY / bottomHeight )';
            cover.style.position = 'fixed';
            filling.style.display = 'none';
        } else {
            cover.style.position = null;
            filling.style.display = 'block';
        }
    });

    const year = new Date().getFullYear();

    return (
        <>
            <div className="hero-container">
                <div className='slogan pic' id='pic1'><p>THE STORY MAGE</p></div>
                <div className='pic' id='pic2'/>
                <div className='pic' id='pic3'/>
                <div className='pic' id='pic4'/>
            </div>
            <Fireflies/>

            <div className="text-sections" style={{paddingTop: '5em'}}>
                <div className='max1024' style={{paddingBottom: "3em"}}>

                    <h1 className='goldenbook blue'
                        style={{textAlign: 'left', padding: "3em 0", fontSize: "20px"}}>WRITE THE
                        STORIES YOU ALWAYS WANTED TO READ</h1>

                    <div className="three-to-one">
                        <Link to="/writing101" reloadDocument={true}>
                            <div className='home-rt home-bottom'>
                                <h4 className="title-descrip" style={{textAlign: 'left'}}><img className="home-icon"
                                                                                               src={require('./Home/hero-images/learn.png')}
                                                                                               alt=""/>&nbsp; Learn
                                </h4>
                                <p className="home-text">Learn more about how to write creative stories. </p>
                            </div>
                        </Link>
                        <Link to="/story-ideas" reloadDocument={true}>
                            <div className='home-rt home-bottom'>
                                <h4 className="title-descrip" style={{textAlign: 'left'}}><img className="home-icon"
                                                                                               src={require('./Home/hero-images/ai.png')}
                                                                                               alt=""/>&nbsp; Explore
                                </h4>
                                <p className="home-text">Choose from 50 story ideas written by OpenAI. </p>
                            </div>
                        </Link>
                        <Link to="/my-stories" reloadDocument={true}>
                            <div className='home-bottom'>
                                <h4 className="title-descrip" style={{textAlign: 'left'}}><img className="home-icon"
                                                                                               src={require('./Home/hero-images/write.png')}
                                                                                               alt=""/>&nbsp; Write
                                </h4>
                                <p className="home-text">Begin writing a new story.</p>
                            </div>
                        </Link>
                    </div>

                    <ChatBot
                        position={"robot-home-position"}
                        scale={"robot robot-scale-home"}
                        gradient={"robot-radial-gradient"}
                        robotText={"robot-text pad-top1"}
                        instructions={"Welcome to The Story Mage!  Whenever you get stuck, I'm just a click away. Get step-by-step guidance on creative writing, brainstorm story ideas, and develop characters that leap off the page."}
                        robotList={"robot-list-none"}
                    />
                </div>
            </div>
            <footer className="footer">
                <p>Created by SimpleDzn &copy; {year}</p>
            </footer>
        </>
    )
}

export default Home
