import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory15 = () => {

// LocalStorage
    const [story15, setstory15] = useLocalStorageState("story15", "")
    // Save if changes are made
    const onChangeStory15Listener = (event) => {
        setstory15(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Mystical Arts</h1>
                    <form>
                        <p className="ai-story-intro">When Calum Claymore enrolls in the prestigious Pembroke School for
                            Mystical Arts, he had no idea what he was getting himself into. A hidden world is waiting
                            for him, full of magical creatures and treacherous secrets. As Calum begins to uncover the
                            mysteries of the school, he discovers that he has a special gift - the ability to summon
                            mystical creatures known as elementals. With the help of his new friends, Fiona and Rowan,
                            Calum must learn to control his power and stop an evil force from taking over the school.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory15Listener} value={story15}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory15;

