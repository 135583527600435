import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory23 = () => {

// LocalStorage
    const [story23, setstory23] = useLocalStorageState("story23", "")
    // Save if changes are made
    const onChangeStory23Listener = (event) => {
        setstory23(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Mind Reader</h1>
                    <form>
                        <p className="ai-story-intro">Sophie is starting school in a new town.
                            She's like any other girl her age, trying to figure out where she fits in and what she wants to do with her
                            life. But Sophie has a secret: she can read people's thoughts. It's both a blessing and
                            a curse. She can't help but overhear other people's conversations, which she
                            often finds embarrassing. But Sophie is determined to use her special ability to help
                            others, and she's starting to figure out how to do just that. She's also made some new
                            friends, including the handsome and mysterious Liam. She doesn't know it yet, but he has
                            a secret too and things are about to get interesting.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory23Listener} value={story23}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory23;

