import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory11 = () => {

// LocalStorage
    const [story11, setstory11] = useLocalStorageState("story11", "")
    // Save if changes are made
    const onChangeStory11Listener = (event) => {
        setstory11(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Spy Games</h1>
                    <form>
                        <p className="ai-story-intro">When I was in high school, the CIA recruited me. My mother's job took us around the
                            world which meant I was fluent in five languages. My parents didn't know I was a
                            genius at computer hacking and had an IQ of 240. But the CIA did. I was the youngest
                            person ever recruited by the CIA. I trained several hours a day in krav maga and
                            Brazilian jiu jitsu. They taught me how to hack into any system. I also learned how to
                            avoid being caught and how to lie convincingly. I've now been given my first assignment:
                            track down a missing fellow spy. His name is Logan. He is on the run after taking a
                            group of scientists hostage. </p>
                        <textarea className="ai-box" onChange={onChangeStory11Listener} value={story11}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory11;

