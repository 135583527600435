import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory51 = () => {

// LocalStorage
    const [story51, setstory51] = useLocalStorageState("story51", "")
    // Save if changes are made
    const onChangeStory51Listener = (event) => {
        setstory51(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>The Fate of the World</h1>
                    <form>
                        <p className="ai-story-intro">I am a citizen of the United States of America. I was born
                            in the year 2035, in the city of Houston, Texas. At the time, it was the most populous
                            city in the country. Now, it's one of the last remaining strongholds of humanity. We've
                            been fighting for years, against the machines that have taken over our planet. I've been
                            told that the sky used to be blue. Now it is blood orange and the air is thick with
                            pollution. The Earth is dying and so are we. The crops are withered and barren, the
                            water is poisoned and the animals are gone. But we don't give up easily. We've been
                            fighting for our lives, for our future. Today, the fate of the entire human species is
                            in our hands. If we succeed, the machines will perish and life as we know it will be
                            over. If we fail, we will face extinction.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory51Listener} value={story51}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory51;

