import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory4 = () => {

// LocalStorage
    const [story4, setstory4] = useLocalStorageState("story4", "")
    // Save if changes are made
    const onChangeStory4Listener = (event) => {
        setstory4(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="max1024">
                    <div className="ai-container">
                        <h1>Trouble in Paradise</h1>
                        <form>
                            <p className="ai-story-intro">It's been four days since the shipwreck and we are running
                                out of options. I was part
                                of a high school semester at sea program. Seventy-five of us were aboard the ship
                                making our way to Mauritius, when the storm hit. In the middle of the night I was jerked
                                awake and heard a loud, terrifying sound. The hull must have slammed into a rock. My
                                roommate, Josh, and I scrambled up the stairs to the top deck where the captain was
                                tossing life jackets to everyone and yelling for us to get into a dingy. Twelve of us
                                made
                                it to a deserted island. At least we think it's deserted. We were able to find some
                                fresh water, but food is scarce. Everyone has begun to give up hope, but I may have an
                                idea for how to save us.
                            </p>
                            <textarea className="ai-box" onChange={onChangeStory4Listener} value={story4}
                                      placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                        </form>
                    </div>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory4;

