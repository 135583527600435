import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory22 = () => {

// LocalStorage
    const [story22, setstory22] = useLocalStorageState("story22", "")
    // Save if changes are made
    const onChangeStory22Listener = (event) => {
        setstory22(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>2048</h1>
                    <form>
                        <p className="ai-story-intro">By the year 2048, ecological collapse, flooding, drought,
                            and severe food shortages have
                            left most of the world devastated. The rich have retreated to their luxury high-rise
                            apartments, while the poor scrabble for food and shelter. Sixteen-year-old Remi is a
                            street-wise orphan who has been scraping by since she was abandoned by her parents as a
                            baby. When Remi is offered a chance to go to school and find a better life, she takes
                            it. But it is not your average school. Students are being raised in artificial
                            intelligence classrooms. They have been genetically engineered to have IQ's over 350 and
                            microchip brain implants link their minds to a massive database. In seconds they can
                            download the knowledge required to fly a helicopter or build a bomb. A few rich people
                            have been hoarding what remains of dwindling resources while the poor are starving. No
                            one had a plan to save the world... until now. Remi finds herself on a journey that will
                            lead her to discover the fate of her parents and herself.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory22Listener} value={story22}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory22;

