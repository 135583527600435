import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory40 = () => {

// LocalStorage
    const [story40, setstory40] = useLocalStorageState("story40", "")
    // Save if changes are made
    const onChangeStory40Listener = (event) => {
        setstory40(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>To the Underworld</h1>
                    <form>
                        <p className="ai-story-intro">Mythology is the story of gods and goddesses, heroes and monsters,
                            love and tragedy. It is a way to make sense of the world around us, to understand the
                            unexplainable, and to explore the depths of human experience. Myths offer us a way to see
                            the world in a different light, to confront our fears and doubts, and to find hope in the
                            darkness. My name is Persephone, and I am a Daughter of Zeus. I was born to save the world,
                            but I didn't know it at the time. My story starts with a journey to the Underworld.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory40Listener} value={story40}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory40;

