import React from 'react';
import './themeSwitcher.css';

function ThemeSwitcher() {
    const [lightMode, setLightMode] = React.useState(false)

    React.useEffect(() => {
        const body = document.body
        const toggle = document.querySelector('.toggle-inner')

        // If light mode is enabled - adds classes to update light-mode styling.
        // Else, removes and styling is as normal.
        if (lightMode === true) {
            body.classList.add('light-mode')
            toggle.classList.add('toggle-active')
        } else {
            body.classList.remove('light-mode')
            toggle.classList.remove('toggle-active')
        }
    }, [lightMode])

    return (
        <header>
            <div
                id="toggle"
                onClick={() => lightMode === false ? setLightMode(true) : setLightMode(false)}
            >
                <div className="toggle-inner"/>
            </div>
        </header>
    )
}

// function Writing101() {
//     return (
//         <div className="writing">
//             <ThemeSwitcher/>
//             <div className="writingcontainer">
//                 <div className="max1024">
//                     <h1>Simple React Light Mode.</h1>
//                     <p>In frontend projects, which seldom require the use of a single-page app, inline styles of DOM elements are often placed in the style="property:value" attribute of the target element. But in React, things are quite different when it comes to styling inline. This guide focuses on exactly how to achieve that using a real-world example of building a user profile card component.</p>
//                 </div>
//             </div>
//         </div>
//     )
// }


export default ThemeSwitcher;