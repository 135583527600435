import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory12 = () => {

// LocalStorage
    const [story12, setstory12] = useLocalStorageState("story12", "")
    // Save if changes are made
    const onChangeStory12Listener = (event) => {
        setstory12(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Tale of the Shapeshifter</h1>
                    <form>
                        <p className="ai-story-intro">I am an eighteen-year-old girl who has been living in a
                            world of my own creation for as
                            long as I can remember. In my world, I am the only one who can see and interact with the
                            magical creatures that live there. My name is Jasmine and I am a shapeshifter. As a
                            shapeshifter I have the power to control the elements and to shape the land. I use my
                            powers to help the people who live in my world and protect them
                            from the creatures that would do them harm. I have always been content living in my own
                            world, but recently, I have started to feel like something is missing. I don't know what
                            it is, but I have a feeling I'm supposed to be doing something else, something
                            bigger.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory12Listener} value={story12}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory12;

