import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory47 = () => {

// LocalStorage
    const [story47, setstory47] = useLocalStorageState("story47", "")
    // Save if changes are made
    const onChangeStory47Listener = (event) => {
        setstory47(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>An Inspector in Paris</h1>
                    <form>
                        <p className="ai-story-intro">Inspector Thomas Andre is a young detective in the Paris
                            police department. He has seen
                            it all--or so he thought. When a gruesome murder occurs in the city's most prestigious
                            museum, Andre is called to the scene. The victim is a well-known art dealer, and the
                            suspect is a young woman with a troubling past. As Andre begins to piece together the
                            clues, he realizes that this is no ordinary case. The victim's family is wealthy,
                            influential, and not afraid to use their connections to get what they want.
                            Andre must navigate the politics of the art world as he races to find the killer. With
                            the help of his trusty sidekick, Gisele, he sets off to solve the case. But when the
                            intricate strands of a twisted crime are linked to something much darker, Andre
                            discovers that there are many in Paris willing to risk everything--even their lives--to
                            protect what they value.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory47Listener} value={story47}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory47;

