import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory50 = () => {

// LocalStorage
    const [story50, setstory50] = useLocalStorageState("story50", "")
    // Save if changes are made
    const onChangeStory50Listener = (event) => {
        setstory50(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Gold Rush</h1>
                    <form>
                        <p className="ai-story-intro">The year is 1848 and thirteen-year-old Claire is determined to
                            find her place in the world. She's grown up on a small farm with her parents and four
                            brothers, and she's eager to make her mark. When she learns of the California Gold Rush, she
                            knows that's where she needs to be. With her parents' permission, she sets off for the West
                            Coast. Claire is tough and not afraid of a little hard work. She's also quick to make
                            friends and soon finds herself in the company of other gold miners. Despite the challenges
                            of the journey and the dangers of the gold fields, Claire is determined to make her fortune.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory50Listener} value={story50}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory50;

