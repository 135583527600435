import {createChatBotMessage} from "react-chatbot-kit";

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  // new method

  greet() {
    const greetingMessage = this.createChatBotMessage("How can I help you?");
    this.updateChatbotState(greetingMessage);
  }

  genre = () =>  {
      const message = this.createChatBotMessage(
          "The most popular genres are mystery, thriller, dystopian, fantasy and science fiction.",
          {
              widget: "genre",
          }
      );
      this.updateChatbotState(message);
  };


    unsure = () => {
    const learningMessage = this.createChatBotMessage(
      "Here are some topics you may find helpful.",
      {
        widget: "learningOptions",
      },
        createChatBotMessage("If what you need isn't listed, can you tell me exactly what you're looking for?"),
    );

    this.updateChatbotState(learningMessage);
  };

    findStory = () => {
        const learningMessage = this.createChatBotMessage(
            "Did you want to",
            {
                widget: "findStory",
            },
            createChatBotMessage("If this is not what you wanted, please text me again."),
        );

        this.updateChatbotState(learningMessage);
    };
  // handleGPTHelpList = () => {
  //   const message = this.createChatBotMessage(
  //     "Got it! Here are a few things I can help you write...",
  //     {
  //       widget: "GPTHelpLinks",
  //     }
  //   );
  //   this.updateChatbotState(message);
  // };

  handleStartWriting = () => {
    const message = this.createChatBotMessage(
        "Perfect. I have these resources for you.",
        {
          widget: "startWriting",
        }
    );
    this.updateChatbotState(message);
  };

  handleLearnToWrite = () => {
    const message = this.createChatBotMessage(
        "Perfect. These resources will get your started.",
        {
          widget: "learnToWrite",
        }
    );
    this.updateChatbotState(message);
  };

  no()  {
    const message = this.createChatBotMessage(
        "Tell me exactly what you're looking for and I'll do my best to help."
    );
    this.updateChatbotState(message);
  };

  save()  {
    const message = this.createChatBotMessage(
        "To save your story, right click and select Save (ctrl S).  Then select the folder on your computer you would like to save your story to. You can change the name of your story by selecting it and typing in the name you want."
    );
    this.updateChatbotState(message);
  };

    aistories= () => {
        const message = this.createChatBotMessage(
            "Here are some great story ideas written by OpenAI.",
            {
                widget: "aistories",
            }
        );
        this.updateChatbotState(message);
    };

  print()  {
    const message = this.createChatBotMessage(
        "To print your story, right click and select Print (ctrl P).  Then in the top right, under Destination, select your printer and click Print. If your printer is not available, click the Save button to save as a PDF."
    );
    this.updateChatbotState(message);
  };

    help() {
    const learningMessage = this.createChatBotMessage("How can I help you?");
    this.updateChatbotState(learningMessage);
  }

  storyIdea= () => {
    const message = this.createChatBotMessage(
        "Looking for story ideas?",
        {
          widget: "storyIdea",
        }
    );
    this.updateChatbotState(message);
  };

    hook= () => {
        const message = this.createChatBotMessage(
            " A killer hook grabs readers in the first sentence, setting the tone and promising an irresistible story.",
            {
                widget: "hook",
            }
        );
        this.updateChatbotState(message);
    };

    storyConcept= () => {
        const message = this.createChatBotMessage(
            "Struggling to find the heart of your story?",
            {
                widget: "storyConcept",
            }
        );
        this.updateChatbotState(message);
    };

  character= () => {
    const message = this.createChatBotMessage(
        "Learn Character Creation",
        {
          widget: "character",
        }
    );
    this.updateChatbotState(message);
  };

  introduction= () => {
    const message = this.createChatBotMessage(
        "Crafting your story's Introduction",
        {
          widget: "introduction",
        }
    );
    this.updateChatbotState(message);
  };

  // name= () => {
  //   const message = this.createChatBotMessage(
  //       "Is this what you were looking for?",
  //       {
  //         widget: "name",
  //       }
  //   );
  //   this.updateChatbotState(message);
  // };

  plot= () => {
    const message = this.createChatBotMessage(
        "How to Craft a Captivating Plot",
        {
          widget: "plot",
        }
    );
    this.updateChatbotState(message);
  };

  // next= () => {
  //   const message = this.createChatBotMessage(
  //       "Got it. Would you like...",
  //       {
  //         widget: "next",
  //       }
  //   );
  //   this.updateChatbotState(message);
  // };
  //
  //   other= () => {
  //       const message = this.createChatBotMessage(
  //           "Would you like",
  //           {
  //               widget: "other",
  //           }
  //       );
  //       this.updateChatbotState(message);
  //   };
  //
  // title= () => {
  //   const message = this.createChatBotMessage(
  //       "I believe this is your best option.",
  //       {
  //         widget: "title",
  //       }
  //   );
  //   this.updateChatbotState(message);
  // };
  //
  // grammar= () => {
  //   const message = this.createChatBotMessage(
  //       "Are you looking for",
  //       {
  //         widget: "grammar",
  //       }
  //   );
  //   this.updateChatbotState(message);
  // };
  //
  // dialogue= () => {
  //   const message = this.createChatBotMessage(
  //       "Perfect. Here's what I suggest.",
  //       {
  //         widget: "dialogue",
  //       }
  //   );
  //   this.updateChatbotState(message);
  // };

    pov= () => {
        const message = this.createChatBotMessage(
            "Point of view is the voice of your story. ",
            {
                widget: "pov",
            }
        );
        this.updateChatbotState(message);
    };

  setting= () => {
    const message = this.createChatBotMessage(
        "Setting the Stage for Your Story.",
        {
          widget: "setting",
        }
    );
    this.updateChatbotState(message);
  };

  prompts= () => {
    const message = this.createChatBotMessage(
        "Need a writing prompt to get you started with a story?",
        {
          widget: "prompts",
        }
    );
    this.updateChatbotState(message);
  };

  updateChatbotState(message) {
    // NOTICE: This function is set in the constructor, and is passed in from the top level Chatbot component. The setState function here actually manipulates the top level state of the Chatbot, so it's important that we make sure that we preserve the previous state.

    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  }
}

export default ActionProvider;
