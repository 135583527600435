import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory46 = () => {

// LocalStorage
    const [story46, setstory46] = useLocalStorageState("story46", "")
    // Save if changes are made
    const onChangeStory46Listener = (event) => {
        setstory46(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Australian Beginnings</h1>
                    <form>
                        <p className="ai-story-intro">It's not often that one gets a second chance at life, but for sixteen-year-old Cody,
                            that's exactly what he gets. After surviving life-threatening injuries from a car crash,
                            he's sent to live with his uncle in Australia. With a new start comes new opportunities
                            and challenges, and Cody is determined to make the most of his second chance. He quickly
                            makes friends at his new school and joins the surf club where he meets a girl named
                            Summer. Summer is determined to help Cody find his place in the world and show him that
                            there's more to Australia than just beaches and surfing. But when a dangerous criminal
                            from Cody's past comes looking for him, Cody has to put everything on the line to
                            protect the people he loves.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory46Listener} value={story46}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory46;

