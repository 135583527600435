import React, {Component} from 'react';
import uuid from 'uuid';
import StoryInput from './StoriesComponents/StoryInput';
import StoriesList from './StoriesComponents/StoriesList';
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import ChatBot from "../../Chatbot/Chatbot";


class Story extends Component {

    state = {
        value: '',
        stories: [
            {
                id: uuid.v4(),
                story: "Write, edit, and delete your stories..."
            }
        ]
    }

    componentDidMount() {
        const stories = JSON.parse(localStorage.getItem('stories')) || [...this.state.stories]
        this.setState({
            stories: stories
        })
    }

    onChange = (e) => {
        this.setState({
            value: e.target.value
        })
    }

    onSubmit = (value) => {
        this.setState({
            stories: [
                ...this.state.stories,
                {
                    id: uuid.v4(),
                    story: value
                }
            ],
            value: ''
        }, () => localStorage.setItem('stories', JSON.stringify(this.state.stories)));
        document.querySelector('textarea').focus();
    }

    editStory = id => {
        const currentStory = this.state.stories.filter(story => story.id === id);
        this.setState({
            value: currentStory[0].story
        }, () => this.deleteStory(currentStory[0].id));
        document.querySelector('textarea').focus();
    }

    deleteStory = id => {
        const stories = this.state.stories.filter(story => story.id !== id);
        this.setState({
            stories
        }, () => localStorage.setItem('stories', JSON.stringify(this.state.stories)))
    }


    render() {
        return (
            <div className="writing">

                <ThemeSwitcher/>
                <h1 className="title">My Stories</h1>

                <StoryInput
                    onSubmit={this.onSubmit}
                    onChange={this.onChange}
                    value={this.state.value}
                />

                <StoriesList
                    stories={this.state.stories}
                    deleteStory={this.deleteStory}
                    editStory={this.editStory}
                />

                <ChatBot
                    position={"robot-icon-position"}
                    scale={"robot robot-scale-icon"}
                    gradient={"robot-radial-gradient-none"}
                    robotText={"robot-text-none"}
                />

            </div>

        )
    }
}

export default Story;