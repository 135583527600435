import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";

const AIStory4 = () => {

// LocalStorage
    const [story4, setstory4] = useLocalStorageState("story4", "")
    // Save if changes are made
    const onChangeStory4Listener = (event) => {
        setstory4(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Wild Frontier</h1>
                    <form>
                        <p className="ai-story-intro">I’m Sophie and I'm just starting out on my own in the world. I’ve
                            been raised on the
                            stories of cowboys and outlaws, and always wanted to see the West for myself. So
                            when I hear that my uncle has left me his ranch in his will, I decide to pack up my
                            horse and head out to Colorado. Little did I know that the ranch was in the middle of
                            nowhere, and that I’d be the only person for miles around. But that’s okay—I’m up for
                            the challenge. I soon discover that there’s more to the West than I expected. Soon I
                            find myself smack in the middle of an outlaw gang's turf...and I have to decide whether
                            to play by their rules. </p>
                        <textarea className="ai-box" onChange={onChangeStory4Listener} value={story4}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory4;

