import React from "react";
import {HashLink} from 'react-router-hash-link';

import "./LinkList.css";

const LinkList = (props) => {
    console.log(props);
    const linkMarkup = props.options.map((link) => (
        <li key={link.id} className="link-list-item">
            <HashLink to={link.url}
                      rel="noopener noreferrer"
                      className="link-list-item-url">
                {link.text}
            </HashLink>
        </li>
    ));

    return <ul className="link-list">{linkMarkup}</ul>;
};

export default LinkList;
