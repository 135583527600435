import React from 'react';
import "./aiStoriesIndex.css";
import '../../../Fireflies/Fireflies';
import "../../css/importImages.css";
import { gsap } from "gsap";
import {Link} from "react-router-dom";
import ChatBot from "../../../Chatbot/Chatbot";
import Fireflies from "../../../Fireflies/Fireflies";


const items = document.querySelectorAll('.item')

items.forEach((el) => {
    const image = el.querySelector('img')

    el.addEventListener('mouseenter', (e) => {
        gsap.to(image, {autoAlpha: 1})
    })

    el.addEventListener('mouseleave', (e) => {
        gsap.to(image, {autoAlpha: 0})
    })

    el.addEventListener('mousemove', (e) => {
        gsap.set(image, {x: e.offsetX - 200})
    })
    el.addEventListener('mousemove', (e) => {
        gsap.set(image, {y: e.offsetY + 100})
    })

})

function AIStoriesIndex() {
    return (

        <>
          <Fireflies />

            <div className="index-sections">
                <div className="index">

                    <Link to="/story1" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Wizardry
                       <span className="reveal"><img src={require('../../css/img/1.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>

                    <Link to="/story2" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">A Vampire's Story
                       <span className="reveal"><img src={require('../../css/img/2.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>

                    <Link to="/story3" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Zombie Survival Guide
                       <span className="reveal reveal2"><img src={require('../../css/img/3.jpg')}
                                                             alt={''}/></span></span></span>
                        </div>
                    </Link>

                    <Link to="/story4" reloadDocument={true}>
                        <div className="index__item">
                            <span className="index__item-text"><span className="index__item-textinner">Wild Frontier
                       <span className="reveal"><img src={require('../../css/img/4.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>

                    <Link to="/story5" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Find the Killer
                       <span className="reveal"><img src={require('../../css/img/5.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story6" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">The Secret War
                       <span className="reveal"><img src={require('../../css/img/6.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story7" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Land of the Elven
                       <span className="reveal"><img src={require('../../css/img/7.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story8" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Unexpected Hero
                       <span className="reveal"><img src={require('../../css/img/8.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story9" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Veil of the Dead
                       <span className="reveal"><img src={require('../../css/img/9.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story10" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Off the Grid
                       <span className="reveal"><img src={require('../../css/img/10.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story11" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Spy Games
                       <span className="reveal"><img src={require('../../css/img/11.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story12" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Tale of the Shapeshifter
                       <span className="reveal reveal2"><img src={require('../../css/img/12.jpg')}
                                                             alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story13" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Robot Adventures
                       <span className="reveal"><img src={require('../../css/img/13.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story14" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Just a Regular Guy
                       <span className="reveal"><img src={require('../../css/img/14.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story15" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Mystical Arts
                       <span className="reveal"><img src={require('../../css/img/15.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story16" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Hidden Away
                       <span className="reveal"><img src={require('../../css/img/16.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story17" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">The Witching Hour
                       <span className="reveal"><img src={require('../../css/img/17.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story18" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Proxima
                       <span className="reveal"><img src={require('../../css/img/18.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story19" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Time Traveler
                       <span className="reveal"><img src={require('../../css/img/19.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story20" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Suddenly a Spy
                       <span className="reveal"><img src={require('../../css/img/20.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story21" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Kingdom of Dorim
                       <span className="reveal"><img src={require('../../css/img/21.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story22" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">2048
                       <span className="reveal"><img src={require('../../css/img/22.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story23" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Mind Reader
                       <span className="reveal"><img src={require('../../css/img/23.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story24" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Jungle Fever
                       <span className="reveal"><img src={require('../../css/img/24.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story25" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Reaching the Surface
                       <span className="reveal"><img src={require('../../css/img/25.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story26" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Dark Hero
                       <span className="reveal"><img src={require('../../css/img/26.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story27" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Making a Wish
                       <span className="reveal"><img src={require('../../css/img/27.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story28" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Italian Secret
                       <span className="reveal"><img src={require('../../css/img/28.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story29" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Digging for Clues
                       <span className="reveal"><img src={require('../../css/img/29.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story30" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Trouble in Paradise
                       <span className="reveal"><img src={require('../../css/img/30.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story31" reloadDocument={true}>
                        <div className="index__item">
                       <span className="index__item-text"><span className="index__item-textinner">Time of the Elves
                       <span className="reveal"><img src={require('../../css/img/31.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story32" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Hidden Treasures
                       <span className="reveal"><img src={require('../../css/img/32.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story33" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">A Bloody Life
                       <span className="reveal"><img src={require('../../css/img/33.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story34" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">The Things I've Seen
                       <span className="reveal"><img src={require('../../css/img/34.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story35" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Elusive Hackers
                       <span className="reveal"><img src={require('../../css/img/35.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story36" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">2165
                       <span className="reveal"><img src={require('../../css/img/36.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story37" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">The Five Factions
                       <span className="reveal"><img src={require('../../css/img/37.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story38" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Outrunning Trouble
                       <span className="reveal"><img src={require('../../css/img/38.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story39" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Equestrian Magic
                       <span className="reveal"><img src={require('../../css/img/39.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story40" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">To the Underworld
                       <span className="reveal"><img src={require('../../css/img/40.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story41" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">A Gateway
                       <span className="reveal"><img src={require('../../css/img/41.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story42" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Floating Cities
                       <span className="reveal"><img src={require('../../css/img/42.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story43" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Haunted
                       <span className="reveal"><img src={require('../../css/img/43.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story44" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">An Egyptian Mystery
                       <span className="reveal"><img src={require('../../css/img/44.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story45" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Finding a New Planet
                       <span className="reveal"><img src={require('../../css/img/45.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story46" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">New Beginnings
                       <span className="reveal"><img src={require('../../css/img/46.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story47" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">An Inspector in Paris
                       <span className="reveal"><img src={require('../../css/img/47.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story48" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Taking Over
                       <span className="reveal"><img src={require('../../css/img/48.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story49" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Alaskan Adventures
                       <span className="reveal"><img src={require('../../css/img/49.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story50" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">Gold Rush
                       <span className="reveal"><img src={require('../../css/img/50.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                    <Link to="/story51" reloadDocument={true}>
                        <div className="index__item">
                        <span className="index__item-text"><span className="index__item-textinner">The Fate of the World
                       <span className="reveal"><img src={require('../../css/img/51.jpg')}
                                                     alt={''}/></span></span></span>
                        </div>
                    </Link>
                </div>
            </div>
            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStoriesIndex