// import logo from '../logo.png';
import * as React from "react"
import '../index.css'
import {slide as Menu} from 'react-burger-menu'
import {Link} from "react-router-dom";
import "../App.css"

var styles = {
    bmBurgerButton: {
        position: 'fixed',
        width: '30px',
        height: '20px',
        right: '36px',
        top: '26px',

    },

    bmBurgerBars: {
        background: '#fff',
        height: '1.5px',

    },

    bmCrossButton: {
        top: '22px',
        right: '38px',
        height: '28px',
        width: '28px'
    },
    bmCross: {
        height: '22px',
        width: '1px',
        background: '#fff'
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
        width: '481px',
        top: 0,
        zIndex: 8000,

    },
    bmMenu: {
        background: 'rgba(2,8,27,0.96)',
        padding: '2.5em 1.5em 0',
        fontSize: '1.25em',
        fontFamily: 'Cera-GR-Light',
        lineHeight: "2.4em",
        textTransform: 'upperCase',
    },

    bmMorphShape: {
        fill: '#373a47'
    },
    bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em'
    },
    bmItem: {
        display: 'flex',
        flexDirection: 'columns',
        color: '#fff'
    },
    bmOverlay: {
        background: 'transparent'
    }
}


const MobileNav = () => {
    const showSettings = (event) => {
        event.preventDefault();
    }

    return (
        <Menu class="mobileNav" right styles={styles}>
            <Link to="/" reloadDocument={true}>Home </Link>
            <Link to="/writing101" reloadDocument={true}>Writing 101</Link>
            <Link to="/story-outline" reloadDocument={true}>Story Outline</Link>
            {/*<Link to="/gpt-help-index" reloadDocument={true}>AI Help Topics</Link>*/}
            <Link to="/story-ideas" reloadDocument={true}>Story Ideas Gallery</Link>
            <Link to="/storyideas-index" reloadDocument={true}>Story Ideas Index</Link>
            <Link to="/my-stories" reloadDocument={true}>My Stories</Link>
            <Link to="/about" reloadDocument={true}>About</Link>
        </Menu>
    );
}

export default MobileNav