import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory4 = () => {

// LocalStorage
    const [story4, setstory4] = useLocalStorageState("story4", "")
    // Save if changes are made
    const onChangeStory4Listener = (event) => {
        setstory4(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="max1024">
                    <div className="ai-container">
                        <h1>Hidden Treasures</h1>
                        <form>
                            <p className="ai-story-intro">Quinn has always been the type of guy to take risks and push
                                boundaries, which has led to more than his fair share of scrapes and close calls. But at
                                least he's never bored. When Quinn learns that his estranged father has died, he figures
                                it's time to go home, settle the estate, and move on with his life. What Quinn doesn't
                                know
                                is that his father has left him a cryptic message that leads him on a wild adventure to
                                uncover a hidden treasure. With the help of a beautiful stranger, Quinn must dodge
                                traps,
                                outsmart enemies, and solve puzzles that will take him to the most exciting places he's
                                ever
                                been. This is one adventure he's never going to forget.
                            </p>
                            <textarea className="ai-box" onChange={onChangeStory4Listener} value={story4}
                                      placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                        </form>
                    </div>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory4;

