import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory27 = () => {

// LocalStorage
    const [story27, setstory27] = useLocalStorageState("story27", "")
    // Save if changes are made
    const onChangeStory27Listener = (event) => {
        setstory27(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="max1024">
                    <div className="ai-container">
                        <h1>Making a Wish</h1>
                        <form>
                            <p className="ai-story-intro">As the first rays of sunlight peek over the horizon, a young
                                girl
                                stirs in her bed. She has a busy day ahead, with plenty of chores to do and lessons to
                                learn. But before she can get up, she has to say goodbye to her best friend. The girl
                                closes
                                her eyes and makes a wish. "Please keep me safe, wherever you are." The girl opens her
                                eyes
                                and rolls out of bed, ready to start her day.
                            </p>
                            <textarea className="ai-box" onChange={onChangeStory27Listener} value={story27}
                                      placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                        </form>
                    </div>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>

    )
}

export default AIStory27;

