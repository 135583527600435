import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory4 = () => {

// LocalStorage
    const [story4, setstory4] = useLocalStorageState("story4", "")
    // Save if changes are made
    const onChangeStory4Listener = (event) => {
        setstory4(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="max1024">
                    <div className="ai-container">
                        <h1>Digging for Clues</h1>
                        <form>
                            <p className="ai-story-intro">Mystery enthusiast Hayley Kincaid is used to digging for
                                clues. So when her dad, a
                                private investigator, goes missing, she naturally takes on the case to track him down.
                                Along the way, she teams up with her best friend and a hunky, wisecracking
                                PI-in-training to follow the trail of clues. But as they get closer to finding her
                                father, they
                                find themselves in the middle of a dangerous conspiracy.
                            </p>
                            <textarea className="ai-box" onChange={onChangeStory4Listener} value={story4}
                                      placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                        </form>
                    </div>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory4;

