import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";

const AIStory16 = () => {

// LocalStorage
    const [story16, setstory16] = useLocalStorageState("story16", "")
    // Save if changes are made
    const onChangeStory16Listener = (event) => {
        setstory16(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Hidden Away</h1>
                    <form>
                        <p className="ai-story-intro">I agreed to help my old friend, Nick, move some boxes in his
                            storage unit. That was my first mistake and unfortunately, not my last one. There, in the
                            back corner, was a large, old steamer trunk. Nick told me he had found it at an auction and
                            had been waiting for the right time to open it. Curiosity got the best of me, so I offered
                            to help him. I could have never predicted what would happen. The moment he opened it, I knew
                            it was the biggest mistake of our lives.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory16Listener} value={story16}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory16;

