import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory25 = () => {

// LocalStorage
    const [story25, setstory25] = useLocalStorageState("story25", "")
    // Save if changes are made
    const onChangeStory25Listener = (event) => {
        setstory25(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="max1024">
                    <div className="ai-container">
                        <h1>Reaching the Surface</h1>
                        <form>
                            <p className="ai-story-intro">As a mermaid, I have spent my entire life living beneath the
                                waves. I have never seen
                                the land, nor do I know what it looks like. But that is all about to change. My father
                                has been chosen to lead our people on a voyage to the surface, and I am to accompany
                                him. I am nervous and excited at the same time. I have heard stories about the land
                                and I can't wait to see it for myself. I am also anxious about what I will find. Will it
                                be a beautiful, peaceful place? Or will it be a harsh, dangerous world? I am about to
                                find out.
                            </p>
                            <textarea className="ai-box" onChange={onChangeStory25Listener} value={story25}
                                      placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                        </form>
                    </div>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory25;

