import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory37 = () => {

// LocalStorage
    const [story37, setstory37] = useLocalStorageState("story37", "")
    // Save if changes are made
    const onChangeStory37Listener = (event) => {
        setstory37(event.target.value)
    }

    return (
       <>
           <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>The Five Factions</h1>
                    <form>
                        <p className="ai-story-intro">In Beatrice Adler's world, society is divided into five factions,
                            each dedicated to the cultivation of a particular virtue: Candor (the honest), Abnegation
                            (the selfless), Dauntless (the brave), Amity (the peaceful), and Erudite (the intelligent).
                            On her sixteenth birthday, Beatrice must decide which faction she will devote her life to.
                            Her choice will shock her family and surprise her friends, but it is the first step in
                            discovering who she truly is.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory37Listener} value={story37}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

           <ChatBot
               position={"robot-icon-position"}
               scale={"robot robot-scale-icon"}
               gradient={"robot-radial-gradient-none"}
               robotText={"robot-text-none"}
           />
       </>
    )
}

export default AIStory37;

