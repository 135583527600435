import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory9 = () => {

// LocalStorage
    const [story9, setstory9] = useLocalStorageState("story9", "")
    // Save if changes are made
    const onChangeStory9Listener = (event) => {
        setstory9(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Veil of the Dead</h1>
                    <form>
                        <p className="ai-story-intro">What if the laws of the universe started to unravel, and the veil between the living
                            and the dead began to thin? For fifteen year old Kira Moore, that's exactly what
                            happens. Kira has always been able to see the dead, but when her mother dies and she's
                            sent to live with her estranged father, she starts to see them more and more. At first,
                            she thinks that she's losing her mind, but she comes to realize that she's not the only
                            one seeing the dead. Neighbors and kids at her school report seeing
                            deceased family members and friends wandering the streets or standing outside the
                            homes they used to live in. Kira is unsure why so many ghosts are suddenly starting to
                            appear and what it means. She must learn to control her ability and use it to
                            help the town figure out what is going on.  </p>
                        <textarea className="ai-box" onChange={onChangeStory9Listener} value={story9}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory9;

