import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";

const AIStory1 = () => {

// LocalStorage
    const [story1, setstory1] = useLocalStorageState("story1", "")
    // Save if changes are made
    const onChangeStory1Listener = (event) => {
        setstory1(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="max1024">
                    <div className="ai-container">
                        <h1>Wizardry</h1>
                        <form>
                            <p className="ai-story-intro">I'm a wizard. I know, it doesn't sound like the most heroic
                                thing
                                in the world, but trust me, it is. I've spent my life honing my skills, learning spells
                                and
                                mastering the arcane arts. And it's all led to this moment, when I finally set out on my
                                epic quest. No, I'm not saving the world from a great evil... yet. I'm just looking for
                                my
                                missing father. He was last seen in the Kingdom of Dorin, and that's where I'm
                                headed.</p>
                            <textarea className="ai-box" onChange={onChangeStory1Listener} value={story1}
                                      placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right).&#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                        </form>
                    </div>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory1;

