import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory8 = () => {

// LocalStorage
    const [story8, setstory8] = useLocalStorageState("story8", "")
    // Save if changes are made
    const onChangeStory8Listener = (event) => {
        setstory8(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>An Unlikely Hero</h1>
                    <form>
                        <p className="ai-story-intro">Ned is an unlikely hero. He spends most of his time fishing and training to be a
                            blacksmith. He's not the kind of person you'd ever expect to find in the middle of an
                            epic adventure. But when his quiet life is upended by the arrival of a mysterious girl,
                            Ned is thrust into a world of dragons, warriors, and a sorcerer who is bent on
                            destroying the world. He wishes he could go back to his quiet life, but he knows he must
                            find a way to stop the evil sorcerer, and he must do it before it's too late. </p>
                        <textarea className="ai-box" onChange={onChangeStory8Listener} value={story8}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory8;

