class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }
  //takes in a messaage and logs it in the screen
  parse(message) {
    const lowercase = message.toLowerCase();
    if (lowercase.includes("hello") || lowercase.includes("hi")) {
      this.actionProvider.greet();
    // } else if (lowercase.includes("ai")) {
    //   this.actionProvider.handleGPTHelpList();
    } else if (lowercase.includes("find my story") || lowercase.includes("where is my story")  || lowercase.includes("where's my story")|| lowercase.includes("story I started") || lowercase.includes("where is my story") || lowercase.includes("where is the story") || lowercase.includes("continue writing") || lowercase.includes("continue with")) {
      this.actionProvider.findStory();
    } else if (lowercase.includes("concep") || lowercase.includes("main")) {
      this.actionProvider.storyConcept();
    } else if (lowercase.includes("story idea") ||  lowercase.includes("for a novel") ||  lowercase.includes("idea")) {
      this.actionProvider.storyIdea();
    } else if (lowercase.includes("print")) {
      this.actionProvider.print();
    } else if (lowercase.includes("ai stories")  ||  lowercase.includes("ai story")) {
      this.actionProvider.aistories();
    } else if (lowercase.includes("save")) {
      this.actionProvider.save();
    } else if (lowercase.includes("charac") || lowercase.includes("protag") || lowercase.includes("antag") || lowercase.includes("villain") || lowercase.includes("kick") || lowercase.includes("main")) {
      this.actionProvider.character();
    } else if (lowercase.includes("start writing") || lowercase.includes("begin writing")) {
      this.actionProvider.handleStartWriting();
    } else if (lowercase.includes("intro") || lowercase.includes("introd") || lowercase.includes("opening") || lowercase.includes("first")) {
      this.actionProvider.introduction();
    //  } else if (lowercase.includes("name")) {
    //   this.actionProvider.name();
    } else if (lowercase.includes("plot") || lowercase.includes("conflic") ) {
      this.actionProvider.plot();
    // } else if (lowercase.includes("next") || lowercase.includes("block") || lowercase.includes("stuck") || lowercase.includes("paragr")) {
    //   this.actionProvider.next();
    // } else if (lowercase.includes("title")) {
    //   this.actionProvider.title();
    // } else if (lowercase.includes("dial") || lowercase.includes("conversa") || lowercase.includes("speech") || lowercase.includes("talk")) {
    //   this.actionProvider.dialogue();
    } else if (lowercase.includes("setti") || lowercase.includes("place") || lowercase.includes("location")  ) {
      this.actionProvider.setting();
    } else if (lowercase.includes("promp")) {
      this.actionProvider.prompts();
    // } else if (lowercase.includes("gramm") || lowercase.includes("proof") || lowercase.includes("spell")) {
    //   this.actionProvider.grammar();
    } else if (lowercase.includes("point") ||  lowercase.includes("view") || lowercase.includes("voice") ||  lowercase.includes("pov")) {
      this.actionProvider.pov();
    } else if (lowercase.includes("genre")) {
      this.actionProvider.genre();
    } else if (lowercase.includes("hook") ||  lowercase.includes("attention") ||  lowercase.includes("grab")) {
      this.actionProvider.hook();
    // } else if (lowercase.includes("other") ||  lowercase.includes("finish") ||  lowercase.includes("conclu")) {
    //   this.actionProvider.other();
    } else if (lowercase.includes("no") || lowercase.includes("not really")) {
      this.actionProvider.no();
    } else if (lowercase.includes("not sure")  ||  lowercase.includes("help") || lowercase.includes("")) {
      this.actionProvider.unsure();
    } else {
      this.actionProvider.unsure();
    }

  }
}
export default MessageParser;
