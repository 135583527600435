import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory19 = () => {

// LocalStorage
    const [story19, setstory19] = useLocalStorageState("story19", "")
    // Save if changes are made
    const onChangeStory19Listener = (event) => {
        setstory19(event.target.value)
    }

    return (
        <>
        <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>Time Traveler</h1>
                    <form>
                        <p className="ai-story-intro">Alice has a secret: she can time travel to any moment in history
                            that she's ever wanted to see. With her trusty time machine, the Tardis (a Doctor Who
                            reference which her classmates don't get), Alice sets off on an epic adventure. She visits
                            ancient Rome, the height of the British Empire, and the Wild West. She meets historical
                            figures like Julius Caesar and Queen Victoria, and experiences first-hand the things they
                            did and saw. But as Alice travels, she begins to realize that her time machine has a
                            dangerous side effect. The more she uses it, the more she starts to change the past. When
                            Alice returns to her present day, her world is torn apart.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory19Listener} value={story19}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory19;

