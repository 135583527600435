import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory34 = () => {

// LocalStorage
    const [story34, setstory34] = useLocalStorageState("story34", "")
    // Save if changes are made
    const onChangeStory34Listener = (event) => {
        setstory34(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="max1024">
                    <div className="ai-container">
                        <h1>The Things I've Seen</h1>
                        <form>
                            <p className="ai-story-intro">I'm not like other people. I can see things that others
                                can't--ghosts, spirits, and
                                other supernatural creatures. It's not that I'm cursed or special, it's just the way I
                                am. And I've learned to live with it. But when my mom dies, I have to move to a small
                                town in Oklahoma to live with my grandparents. At first, I'm excited to be living in the
                                same house as my mom did as a child. Soon, however, I realize that the house is haunted.
                                And
                                not just by regular ghosts, but by evil spirits who want to kill me. But I'm not going
                                to let them win. I'm going to fight back and figure out what happened to my mom and
                                why these spirits are targeting me.
                            </p>
                            <textarea className="ai-box" onChange={onChangeStory34Listener} value={story34}
                                      placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                        </form>
                    </div>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory34;

