import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory41 = () => {

// LocalStorage
    const [story41, setstory41] = useLocalStorageState("story41", "")
    // Save if changes are made
    const onChangeStory41Listener = (event) => {
        setstory41(event.target.value)
    }

    return (
        <>
            <div className="writing">
            <ThemeSwitcher/>
            <div className="max1024">
                <div className="ai-container">
                    <h1>The Getaway</h1>
                    <form>
                        <p className="ai-story-intro">In a world of over-scheduled lives and crammed cities, Liza longs for a place to truly
                            escape. So when her great-aunt dies and leaves her an uninhabited island, she can't wait
                            to claim it as her own. With only her wits, her kayak, and her dog, Leo, Liza
                            starts to build her own paradise. But as she starts to make her island home, Liza
                            discovers that she's not alone. Someone else is there, watching her, and they're not
                            happy about her arrival.
                        </p>
                        <textarea className="ai-box" onChange={onChangeStory41Listener} value={story41}
                                  placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                    </form>
                </div>
            </div>
        </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory41;

