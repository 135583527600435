import React from "react";
import {useLocalStorageState} from "./js/useLocalStorageState";
import ThemeSwitcher from "../../MyStories/ThemeSwitcher/ThemeSwitcher";
import '../aiStories.css'
import ChatBot from "../../Chatbot/Chatbot";


const AIStory24 = () => {

// LocalStorage
    const [story24, setstory24] = useLocalStorageState("story24", "")
    // Save if changes are made
    const onChangeStory24Listener = (event) => {
        setstory24(event.target.value)
    }

    return (
        <>
            <div className="writing">
                <ThemeSwitcher/>
                <div className="max1024">
                    <div className="ai-container">
                        <h1>Jungle Fever</h1>
                        <form>
                            <p className="ai-story-intro">Hunter Morgan had always wanted to explore the Amazon jungle.
                                So
                                when he learned about a new expedition that would be led by world-renowned explorer, Dr.
                                Lawrence Carter, he knew he had to sign up. Hunter is excited for the adventure of a
                                lifetime, but he has no idea what he's in for. The jungle is a harsh and dangerous
                                place,
                                and the expedition quickly turns into a nightmare. Hunter must use all of his skills and
                                courage to survive the treacherous journey.
                            </p>
                            <textarea className="ai-box" onChange={onChangeStory24Listener} value={story24}
                                      placeholder="Continue writing the story here.&#13;Be sure to Bookmark this page so you can easily find it later. &#13;If you get stuck, your AI Writing Assistant can help (bottom right). &#13;Your story will be saved to your local storage automatically. If you clear your cookies/cache you will delete your story. To back up your work, you can copy/paste it into another document. Or, press Ctrl p (or right click) and select Print. Under Destination (top right), choose Save as PDF."/>
                        </form>
                    </div>
                </div>
            </div>

            <ChatBot
                position={"robot-icon-position"}
                scale={"robot robot-scale-icon"}
                gradient={"robot-radial-gradient-none"}
                robotText={"robot-text-none"}
            />
        </>
    )
}

export default AIStory24;

